import PvModel from './base/model'
import apiEventController from "../controllers/apiEventController";

export default class PvEventModel extends PvModel {
    constructor() {
        super(new apiEventController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'name',
                max: 128,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'url',
                max: 191,
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'description',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'short_description',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'image',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'address',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'map_link',
                max: 191,
                nullable: true
            },
            /*{
                validator: 'validateInteger',
                attribute: 'start_date',
                nullable: true
            },*/
            {
                validator: 'validateInteger',
                attribute: 'max_seats',
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'title',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'h1',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'seo_description',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'keywords',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'robots',
                max: 64,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'canonical',
                max: 256,
                nullable: true
            },
        ];
    }

    get id(){
        return super.getAttribute('id')
    }

    get created_at(){
        return super.getAttribute('created_at')
    }

    get updated_at(){
        return super.getAttribute('updated_at')
    }

    get name(){
        return super.getAttribute('name')
    }

    get url(){
        return super.getAttribute('url')
    }

    get description(){
        return super.getAttribute('description')
    }

    get short_description(){
        return super.getAttribute('short_description')
    }

    get image(){
        return super.getAttribute('image')
    }

    get address(){
        return super.getAttribute('address')
    }

    get map_link(){
        return super.getAttribute('map_link')
    }

    get start_date(){
        return super.getAttribute('start_date')
    }

    get max_seats(){
        return super.getAttribute('max_seats')
    }

    get title(){
        return super.getAttribute('title')
    }

    get h1(){
        return super.getAttribute('h1')
    }

    get seo_description(){
        return super.getAttribute('seo_description')
    }
    get keywords(){
        return super.getAttribute('keywords')
    }

    get robots(){
        return super.getAttribute('robots')
    }

    get canonical(){
        return super.getAttribute('canonical')
    }


    set id(value){
        super.setAttribute('id', value);
    }

    set name(value){
        super.setAttribute('name', value)
    }

    set url(value){
        super.setAttribute('url', value)
    }

    set description(value){
        super.setAttribute('description', value)
    }

    set short_description(value){
        super.setAttribute('short_description', value)
    }

    set image(value){
        super.setAttribute('image', value)
    }

    set address(value){
        super.setAttribute('address', value)
    }

    set map_link(value){
        super.setAttribute('map_link', value)
    }

    set start_date(value){
        super.setAttribute('start_date', value)
    }

    set max_seats(value){
        super.setAttribute('max_seats', value)
    }

    set title(value){
        super.setAttribute('title', value);
    }

    set h1(value){
        super.setAttribute('h1', value);
    }

    set seo_description(value){
        super.setAttribute('seo_description', value);
    }

    set keywords(value){
        super.setAttribute('keywords', value);
    }

    set robots(value){
        super.setAttribute('robots', value);
    }

    set canonical(value){
        super.setAttribute('canonical', value);
    }

}