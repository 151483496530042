<template lang="pug">
    .news-subscribe
        p.news-categoties.d-none
            | Категорії новін:
            span останні
            span останні
            span останні
            span останні
            span останні
            span останні
        .news-subscribe-head.text-center Отримуйте новини
            br
            span першими
        .news-subscribe-form
            v-text-field(label="Введіть ваше ім'я" v-model="name" color="#90D21F" hide-details)
            v-text-field(label="Введіть ваш e-mail" v-model="email" color="#90D21F" hide-details)
            button.btn.block-center підписатися
        .news-subscribe-social
            p.text-center
                | Також слiдкуйте за нами
                br
                |  в соц. мережах
            .social
                a(:href="social.link" v-for="social in contacts.socials")
                    i.fab.fa-facebook-f(v-if="social.name === 'Facebook'")
                    i.fab.fa-instagram(v-if="social.name === 'Instagram'")
                    i.fab.fa-viber(v-if="social.name === 'Viber'")
                    i.fab.fa-telegram-plane(v-if="social.name === 'Telegram'")
</template>

<script>
    export default {
        name: "NewsSubscribe",
        data() {
            return {
                name: '',
                email: ''
            }
        },
        methods: {
            subscribe() {

            }
        },
        computed: {
            contacts() {
                return this.$store.getters.contactsOptions
            }
        },
    }
</script>

<style lang="sass">
    .news-subscribe
        padding: 50px 69px 70px
        background: #FFFFFF
        box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.1)
        @media (max-width: 1199px)
            padding: 30px
        @media (max-width: 500px)
            padding: 15px

        .news-categoties
            display: flex
            flex-wrap: wrap
            justify-content: flex-start
            padding: 0 25px
            margin-bottom: 50px
            font-size: 20px
            line-height: 23px
            font-weight: bold
            @media (max-width: 1199px)
                padding: 0
                margin-bottom: 15px

            span,
            a
                font-weight: normal
                color: #90D21F !important
                text-decoration: underline #90D21F
                transition: .5s
                cursor: pointer

                &:first-child
                    margin-left: 7px

                &:not(:last-child)
                    margin-right: 7px

                &:hover
                    text-decoration: underline transparent

        .news-subscribe-head
            font-size: 28px
            line-height: 33px
            font-weight: bold
            color: #262323
            margin-bottom: 30px

            span
                color: #f49a05

        .news-subscribe-form
            margin-bottom: 40px

            .v-text-field
                margin-bottom: 26px

                &:last-of-type
                    margin-bottom: 50px

                .v-label
                    color: #B9C7A1
                    font-size: 18px

                .v-input__slot:before
                    border-color: #B9C7A1 !important

        .news-subscribe-social
            p
                font-size: 18px
                line-height: 21px
                color: #929292
                font-weight: bold
                margin-bottom: 30px

            .social
                display: flex
                align-content: center
                justify-content: center

                a
                    transition: .5s
                    font-size: 23px
                    &:not(:last-child)
                        margin-right: 50px
                    &:hover
                        color: #B9C7A1 !important

</style>