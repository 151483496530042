import PageModel from "../sdk/classes/models/pageModel";

export default {
    state: {
        contactsOptions: {
            address: [],
            email: '',
            gmaps: '',
            phones: [],
            socials: [],
            time: ''
        }
    },
    getters: {
        contactsOptions(state) {
            return state.contactsOptions
        },
    },
    mutations: {
        loadContactsOptions(state, payload) {
            payload.forEach((cv) => {
                if(cv.value[0] === '[')
                    state.contactsOptions[cv.name] = JSON.parse(cv.value)
                else
                    state.contactsOptions[cv.name] = cv.value
            })
        },
        setContactsOptions(state, payload) {
            state.contactsOptions = payload
        }
    },
    actions: {
        async getContacts ({commit}) {
            try{
                let page = await PageModel.getById(2);
                commit('loadContactsOptions', page.options)
            } catch (e) {
                console.log(e)
            }

        },
    }
}