import Vue from 'vue'
import Vuex from 'vuex'

import home from './home'
import contacts from "./contacts";
import actions from "./actions";
import news from "./news";
import locations from "./locations";
import servicesCategories from "./servicesCategories";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        home,
        contacts,
        actions,
        news,
        locations,
        servicesCategories
    }
})
