<template lang="pug">
  .action-page
    section.content-wrap
      template(v-if="event")
        section.breadcrumbs
          .container-custom
            .row
              .col-md-12
                ul.breadcrumbs
                  li
                    router-link(to="/" tag="a") Головна
                  li
                    router-link(:to="{name: 'Actions'}" tag="a") Події
                  li {{ event.name }}
        section.content
          .container-custom
            .row.justify-between
              .col-12
                h2.section-head.section-head-dark.text-center.action-full-name Подія
                  span  «{{ event.name }}»
              .col-lg-6.text-center
                img(:src="event.image").main-image
              .col-lg-5
                .action-description
                  .time
                    img(src="../../img/home/action-time-icon.png")
                    p {{ event.start_date }}
                  .place
                    img(src="../../img/home/action-place-icon.png")
                    p {{ event.address }}
                .action-short-description
                  p.head Короткий опис
                  div(v-html="event.short_description")
                .action-subscribe-places місць залишилось: {{ event.max_seats }}
                button.btn зареєструватись
            .row.d-none
              .col-12
                h2.section-head.section-head-dark.text-center
                  span  Команда спеціалістів та резидентів
              .col-12
                p Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.
                p Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.
            .row
              .col-12.d-none
                h2.section-sub-head.text-center Ми створили простір
              .col-12.main-content(v-html="event.description")
      template(v-else)
        section.breadcrumbs
          .container-custom
            .row.justify-center
              .col-md-12.text-center
                v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>

export default {
  name: "Action",
  props: ['url'],
  computed: {
    event() {
      return this.$store.getters.actionUrl(this.url)
    }
  },
  mounted() {
    if (this.$store.getters.actions.length === 0)
      this.$store.dispatch('getActions')
  }
}
</script>

<style>

</style>