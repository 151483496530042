import ApiRequest from '../../components/apiRequest.js'
import ApiAuthenticator from './apiAuthenticator.js'
import ApiModelList from '../../models/base/modelList.js'
import HttpCodesExceptions from './httpCodesExceptions.js'

export default class PvApiLoop extends ApiAuthenticator {
    constructor(modelClass, baseUrl, filters, totalCount, pagesCount, page = 2, fields = null) {
        super();
        this._request = new ApiRequest();

        this._modelClass = modelClass;
        this._baseUrl = baseUrl;
        this._filters = filters;

        this._page = page;
        this._totalCount = totalCount;
        this._pagesCount = pagesCount;
        this._success = false;
        this._response = null;
        this._fields = fields;
    }

    get response() {
        return this._response;
    }

    get models() {
        if (!this.isSuccess())
            return null;

        return ApiModelList.make(this._modelClass, this._response.data);
    }

    isSuccess() {
        return this._success;
    }

    beforeRequest() {
        this._response = null;
        this._success = false;
        super.authenticate();
    }

    afterRequest() {
        if (!this._response)
            return;

        HttpCodesExceptions.checkHttpCode(this._response.httpCode, this._response);

        this._success = true;
        super.setBearerFromResponse(this._response);
        this.setMeta();
    }

    validateRequestData(filters, page = 0, fields = null) {
        let data = {};

        if (filters != null)
            data['filter'] = filters;

        if (fields != null)
            data['fields'] = fields;

        if (page > 1)
            data['page'] = page;

        return data;
    }

    hasNextPage() {
        return this._page <= this._pagesCount;
    }

    setMeta() {
        this._totalCount = parseInt(this._response.getHeader('x-pagination-total-count'));
        this._pagesCount = parseInt(this._response.getHeader('x-pagination-page-count'));
    }

    async nextPage() {
        this.beforeRequest();
        if (await this._request.request(this._baseUrl, this.validateRequestData(this._filters, this._page++, this._fields))) {
            this._response = this._request.getResponse();
        }
        this.afterRequest();

        if (!this.isSuccess())
            return false;

        return true;
    }
}