import ApiBadRequestException from '../../exception/badRequestException.js'
import ApiForbiddenException from '../../exception/forbiddenException.js'
import ApiMethodNotAllowedException from '../../exception/methodNotAllowedException.js'
import ApiNotFoundException from '../../exception/notFoundException.js'
import ApiServerErrorException from '../../exception/serverErrorException.js'
import ApiTooManyRequestsException from '../../exception/tooManyRequestsException.js'
import ApiUnauthorizedException from '../../exception/unauthorizedException.js'
import ApiUnprocessableEntityException from '../../exception/unprocessableEntityException.js'
import ApiUnsupportedMediaTypeException from '../../exception/unsupportedMediaTypeException.js'

export default class PvApiHttpCodesExceptions {

    static checkHttpCode(httpCode, response = null) {
        if (httpCode === 400)
            throw new ApiBadRequestException(response);
        else if (httpCode === 401)
            throw new ApiUnauthorizedException(response);
        else if (httpCode === 403)
            throw new ApiForbiddenException(response);
        else if (httpCode === 404)
            throw new ApiNotFoundException(response);
        else if (httpCode === 405)
            throw new ApiMethodNotAllowedException(response);
        else if (httpCode === 415)
            throw new ApiUnsupportedMediaTypeException(response);
        else if (httpCode === 422)
            throw new ApiUnprocessableEntityException(response);
        else if (httpCode === 429)
            throw new ApiTooManyRequestsException(response);
        else if (httpCode >= 500 && httpCode < 600)
            throw new ApiServerErrorException(response);
    }

}