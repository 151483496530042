import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/Home.vue'
import NotFound from "../components/NotFound"
import Actions from "../components/Actions/Actions";
import Action from "../components/Actions/Action";
import Service from "../components/Services/Service";
import ThanksPage from "../components/ThanksPage";
import NewsList from "../components/News/NewsList";
import NewsSingle from "../components/News/NewsSingle";
import About from "../components/About";
import Locations from "../components/Locations";
import Contacts from "../components/Contacts";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/actions/',
        name: 'Actions',
        component: Actions
    },
    {
        path: '/action/:url',
        name: 'Action',
        props: true,
        component: Action
    },
    {
        path: '/service/:url',
        name: 'Service',
        props: true,
        component: Service
    },
    {
        path: '/thanks',
        name: 'ThanksPage',
        props: true,
        component: ThanksPage
    },
    {
        path: '/news',
        name: 'NewsList',
        component: NewsList
    },
    {
        path: '/news/:url',
        name: 'NewsSingle',
        props: true,
        component: NewsSingle
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Locations
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    routes
})

export default router
