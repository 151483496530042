//import ApiResponse from '../../components/apiResponse.js'
import ApiRequest from '../../components/apiRequest.js'
import ApiAuthenticator from './apiAuthenticator.js'
import ApiLoop from './apiLoop.js'
import HttpCodesExceptions from './httpCodesExceptions.js'
import PvModelList from "../../models/base/modelList"

export default class PvApiController extends ApiAuthenticator {
    constructor() {
        super();
        this._request = new ApiRequest();
        this._baseUrl = null;
        this._success = false;
        this._response = null;
        this._modelClass = null;
        this._loop = null;
    }

    isSuccess() {
        return this._success;
    }

    beforeRequest() {
        this._response = null;
        this._success = false;
        //this._loop = null;
        super.authenticate();
    }

    afterRequest() {
        if (!this._response)
            return;

        HttpCodesExceptions.checkHttpCode(this._response.httpCode, this._response);

        this._success = true;
        super.setBearerFromResponse(this._response);
    }

    validateRequestData(filters, page = 0, fields = null) {
        let data = {};

        if (filters != null)
            data['filter'] = filters;

        if (fields != null)
            data['fields'] = fields;

        if (page > 1)
            data['page'] = page;

        return data;
    }

    /**
     *
     * @param filters
     * @param page
     * @returns {Promise<ApiResponse>}
     */
    async getAll(filters = null, page = 1, fields = null) {
        this.beforeRequest();

        if (await this._request.request(this._baseUrl, this.validateRequestData(filters, page))) {
            this._response = this._request.getResponse();
        }

        this.afterRequest();

        let loopItems = await this.makeLoop(filters, fields)

        return loopItems.getAll();
    }


    async makeLoop(filters = null, fields = null) {
        this.setLoop(filters, 1, fields);
        let items = new PvModelList();
        this.beforeRequest();

        do {
            await this._loop.nextPage()
            items.insertMany(this._loop.models.getAll())
        } while (this._loop.hasNextPage())

        this.afterRequest();
        //return this.setLoop(filters, 1) !== false;
        return items;
    }

    setLoop(filters, page = 1, fields = null) {
        if (!this.isSuccess())
            return false;

        this._loop = new ApiLoop(
            this._modelClass,
            this._baseUrl,
            filters,
            parseInt(this._response.getHeader('x-pagination-total-count')),
            parseInt(this._response.getHeader('x-pagination-page-count')),
            page,
            fields
        );
    }

    get loop() {
        return this._loop;
    }

    hasLoop() {
        return this._loop instanceof ApiLoop;
    }

    /**
     *
     * @param id
     * @returns {Promise<null|boolean|ApiResponse>}
     */
    async getOne(id) {
        if (!Number.isInteger(parseInt(id)))
            return false;

        this.beforeRequest();

        if (await this._request.request(`${this._baseUrl}/${id}`)) {
            this._response = this._request.getResponse();
        }

        this.afterRequest();

        return this._response;
    }

    async getOneModel(id) {
        let response = await this.getOne(id);
        if (response.httpCode != 200)
            return null;

        let model = new this._modelClass;
        model.setAttributes(response.data);
        return model;
    }



    getAttributesFromModel(attributes) {
        //var attributes = model._attributes;
        for(var i in attributes) {
            var cv = attributes[i];
            if (typeof cv === 'object') {
                if (cv.hasOwnProperty('_attributes')) {
                    attributes[i] = cv._attributes;
                } else {

                    for(var i2 in cv) {
                        var cv2 = cv[i2]
                        if (typeof cv2 === 'object') {
                            if (cv2.hasOwnProperty('_attributes')) {
                                attributes[i][i2] = cv2._attributes;
                            }
                        }
                    }

                }
            }
        }
        return attributes;
        /*attributes.forEach(function (cv, i) {
            if (typeof cv === 'object') {
                if (cv.indexOf('_attributes') !== -1) {
                    attributes[i] = cv._attributes;
                } else {

                    cv.forEach(function (cv2, i2) {
                        if (typeof cv2 === 'object') {
                            if (cv2.indexOf('_attributes') !== -1) {
                                attributes[i][i2] = cv2._attributes;
                            }
                        }
                    });

                }
            }
        });*/

    }

    async create(model) {
        this.beforeRequest();


        if (await this._request.request(`${this._baseUrl}`, this.getAttributesFromModel(model), 'post')) {
            this._response = this._request.getResponse();
        }

        this.afterRequest();

        return this._response;
    }

    async update(model) {
        if (!Number.isInteger(parseInt(model.id)))
            return false;

        this.beforeRequest();

        if (await this._request.request(`${this._baseUrl}/${model.id}`, this.getAttributesFromModel(model), 'put')) {
            this._response = this._request.getResponse();
        }

        this.afterRequest();

        return this._response;
    }

    async delete(id) {
        if (!Number.isInteger(parseInt(id)))
            return false;

        this.beforeRequest();

        if (await this._request.request(`${this._baseUrl}/${id}`, null, 'delete')) {
            this._response = this._request.getResponse();
        }

        this.afterRequest();

        return this._response;
    }
}