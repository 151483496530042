import ApiController from './base/apiController'
import TeamMemberModel from "../models/teamMemberModel";

export default class PvApiTeamMemberController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'team-members';
        this._modelClass = TeamMemberModel;
    }
}