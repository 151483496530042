import PageModel from "../sdk/classes/models/pageModel";

export default {
    state: {
        homeOptions: {
            aboutBlocks: [],
            headSlides: [],
            partners: []
        }
    },
    getters: {
        homeOptions(state) {
            return state.homeOptions
        },
        headSlider(state) {
            return state.homeOptions.headSlides
        },
        aboutBlocks(state) {
            return state.homeOptions.aboutBlocks
        },
        partnersHome(state) {
            return state.homeOptions.partners
        },
    },
    mutations: {
        loadHome(state, payload) {
            payload.forEach((cv) => {
                state.homeOptions[cv.name] = JSON.parse(cv.value)
            })
        },
    },
    actions: {
        async getHome ({commit}) {
            try{
                let page = await PageModel.getById(1);
                commit('loadHome', page.options)
            } catch (e) {
                console.log(e)
            }

        },
    }
}