import PvModel from './base/model'
import apiTeamMemberController from "../controllers/apiTeamMemberController";

export default class PvTeamMemberModel extends PvModel {
    constructor() {
        super(new apiTeamMemberController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
        ];
    }

    get id(){
        return super.getAttribute('id');
    }

    get fullName(){
        return super.getAttribute('fullName');
    }

    get position(){
        return super.getAttribute('position');
    }

    get specialty(){
        return super.getAttribute('specialty');
    }

    get linkToRecord(){
        return super.getAttribute('linkToRecord');
    }

    get photo(){
        return super.getAttribute('photo');
    }

    get category(){
        return super.getAttribute('category');
    }

    get schedule(){
        return super.getAttribute('schedule');
    }

    get show(){
        return super.getAttribute('show');
    }

    get serviceGroupId(){
        return super.getAttribute('serviceGroupId');
    }

    get created_at(){
        return super.getAttribute('created_at');
    }

    get updated_at(){
        return super.getAttribute('updated_at');
    }

    get servicesBlock(){
        return super.getAttribute('servicesBlock');
    }

    set id(value){
        super.setAttribute('id', value);
    }

    set fullName(value){
        super.setAttribute('fullName', value);
    }

    set position(value){
        super.setAttribute('position', value);
    }

    set specialty(value){
        super.setAttribute('specialty', value);
    }

    set linkToRecord(value){
        super.setAttribute('linkToRecord', value);
    }

    set photo(value){
        super.setAttribute('photo', value);
    }

    set category(value){
        super.setAttribute('category', value);
    }

    set schedule(value){
        super.setAttribute('schedule', value);
    }

    set show(value){
        super.setAttribute('show', value);
    }

    set serviceGroupId(value){
        super.setAttribute('serviceGroupId', value);
    }

    set servicesBlock(value){
        super.setAttribute('servicesBlock', value);
    }
}