import PvModel from './base/model'
import apiServiceController from "../controllers/apiServiceController";

export default class PvServiceModel extends PvModel {
    constructor() {
        super(new apiServiceController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
        ];
    }

    get id(){
        return super.getAttribute('id');
    }

    get name(){
        return super.getAttribute('name');
    }

    get description(){
        return super.getAttribute('description');
    }

    get photo(){
        return super.getAttribute('photo');
    }

    get doingTime(){
        return super.getAttribute('doingTime');
    }

    get serviceGroupId(){
        return super.getAttribute('serviceGroupId');
    }

    get link(){
        return super.getAttribute('link');
    }

    get created_at(){
        return super.getAttribute('created_at');
    }

    get updated_at(){
        return super.getAttribute('updated_at');
    }

    set id(value){
        super.setAttribute('id', value);
    }

    set name(value){
        super.setAttribute('name', value);
    }

    set description(value){
        super.setAttribute('description', value);
    }

    set photo(value){
        super.setAttribute('photo', value);
    }

    set doingTime(value){
        super.setAttribute('doingTime', value);
    }

    set serviceGroupId(value){
        super.setAttribute('serviceGroupId', value);
    }

    set link(value){
        super.setAttribute('link', value);
    }
}