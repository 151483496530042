import PvApiResponse from './apiResponse'
import axios from 'axios'
import QS from 'qs';
//import store from "../../../store";

export default class PvApiRequest {
    constructor() {
        this._headers = {
            'Accept': 'application/json,text/*;q=0.99',
        };

        this._response = null;

        this._axios = axios.create({baseURL: 'https://pv.roidev.top'});//todo изменить в лайве baseURL на https://pv.roidev.top дев /
    }

    setHeaders(headers) {
        if (headers) {
            this._headers = headers;
            return true;
        }
        return false;
    }

    addHeader(key, data) {
        if (key === undefined || key === null || data === undefined || data === null)
            return false;

        this._headers[key] = data;

        return true;
    }

    getHeaders() {
        return this._headers;
    }

    getHeader(key) {
        if (key === undefined || key === null || !this._headers.hasOwnProperty(key))
            return null;
        return this._headers[key];
    }

    /**
     * @returns PvApiResponse|null
     */
    getResponse() {
        if (this._response === undefined)
            return null;
        return this._response;
    }

    _validateAndGetMethod(type) {
        if (type === undefined || type === null || ['delete', 'post', 'put'].indexOf(type.toLowerCase()) === -1)
            return 'get';
        return type.toLowerCase();
    }

    _validateAndGetData(data, method) {
        if (data === undefined || data === null || method === 'get')
            return null;
        return data;
    }

    _validateAndGetParams(data, method) {
        if (data === undefined || data === null || method !== 'get')
            return null;
        return data;
    }

    async request(url, data = null, type = 'get') {
        if (url === undefined || url === null)
            return false;

        let method = this._validateAndGetMethod(type);
        let _data = this._validateAndGetData(data, method);
        let _params = this._validateAndGetParams(data, method);
        let response = null;

        let config = {
            url: url,
            method: method,
            headers: this._headers,
            paramsSerializer: function (params) {
                return QS.stringify(params, {arrayFormat: 'brackets'})
            }
        };


        if (_params !== null) {
            config['params'] = _params;
        } else if (_data !== null) {
            config['data'] = _data;
        }

        try {
            response = await this._axios.request(config);
        } catch (error) {
            response = error.response;
        }

        this._response = new PvApiResponse(response);

        return true;
    }
}