import NewsController from "../sdk/classes/controllers/apiNewsController";

export default {
    state: {
        news: []
    },
    getters: {
        newsAll(state) {
            return state.news
        },
        newsUrl(state) {
            return url => {
                if(state.news !== null)
                    return state.news.find(news => news.url === url)
            }
        },
        newsText(state) {
            let newsText = [];
            if(state.news !== null){
                state.news.forEach(function (item) {
                    if (item.youtube === null)
                        newsText.push(item)
                })
            }
            return newsText
        }
    },
    mutations: {
        loadNews(state, payload) {
            state.news = payload
        },
    },
    actions: {
        async getNews ({commit}) {
            try{
                let news = await (new NewsController).getAll();
                console.log(news)
                /*news.forEach(function (item) {
                    if(item.youtube !== null)
                        item['play'] = false
                })
                commit('loadNews', news)*/
            } catch (e) {
                console.log(e)
            }

        },
    }
}