<template lang="pug">
    .thanks-page
        section.thanks-head-wrap
            .container
                .row
                    .col-12
                        .section-head.text-center Дякуємо за те, що приєдналися до нас.
                        .section-sub-head.text-center З вами зв`яжуться найближчим часом.
        section.padding-t-0
            .container
                .row.justify-center
                    .col-md-6.col-lg-4
                        p.head Можете перейти на ці:
                        ul.menu
                            li(v-for="link in links", :key="link.title")
                                router-link(:to="link.url") {{ link.title }}
</template>

<script>
    export default {
        name: "ThanksPage",
        data () {
            return {
                links: [
                    {
                        title: 'Головна',
                        url: '/'
                    },
                    {
                        title: 'Про нас',
                        url: '/about'
                    },
                    {
                        title: 'Послуги',
                        url: '/services'
                    },
                    {
                        title: 'Події',
                        url: '/actions'
                    },
                    {
                        title: 'Галерея',
                        url: '/gallery'
                    },
                    {
                        title: 'Контакти',
                        url: '/contacts'
                    },
                ]
            }
        }
    }
</script>