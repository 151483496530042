import PvModel from './base/model'
import apiNewsController from "../controllers/apiNewsController";

export default class PvNewsModel extends PvModel {
    constructor() {
        super(new apiNewsController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'name',
                max: 128,
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'url',
                max: 191,
                nullable: false
            },
            {
                validator: 'validateInteger',
                attribute: 'author_id',
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'image',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateInteger',
                attribute: 'category_id',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'content',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'youtube',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'title',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'h1',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'description',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'keywords',
                max: 65535,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'robots',
                max: 64,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'canonical',
                max: 256,
                nullable: true
            },
        ];
    }

    get id(){
        return super.getAttribute('id');
    }

    get name(){
        return super.getAttribute('name');
    }

    get url(){
        return super.getAttribute('url');
    }

    get author_id(){
        return super.getAttribute('author_id');
    }

    get image(){
        return super.getAttribute('image');
    }

    get category_id(){
        return super.getAttribute('category_id');
    }

    get content(){
        return super.getAttribute('content');
    }

    get youtube(){
        return super.getAttribute('youtube');
    }

    get created_at(){
        return super.getAttribute('created_at');
    }

    get updated_at(){
        return super.getAttribute('updated_at');
    }

    get title(){
        return super.getAttribute('title')
    }

    get h1(){
        return super.getAttribute('h1')
    }

    get description(){
        return super.getAttribute('description')
    }

    get keywords(){
        return super.getAttribute('keywords')
    }

    get robots(){
        return super.getAttribute('robots')
    }

    get canonical(){
        return super.getAttribute('canonical')
    }



    set id(value){
        super.setAttribute('id', value);
    }

    set name(value){
        super.setAttribute('name', value);
    }

    set url(value){
        super.setAttribute('url', value);
    }

    set author_id(value){
        super.setAttribute('author_id', value);
    }

    set image(value){
        super.setAttribute('image', value);
    }

    set category_id(value){
        super.setAttribute('category_id', value);
    }

    set content(value){
        super.setAttribute('content', value);
    }

    set youtube(value){
        super.setAttribute('youtube', value);
    }

    set title(value){
        super.setAttribute('title', value);
    }

    set h1(value){
        super.setAttribute('h1', value);
    }

    set description(value){
        super.setAttribute('description', value);
    }

    set keywords(value){
        super.setAttribute('keywords', value);
    }

    set robots(value){
        super.setAttribute('robots', value);
    }

    set canonical(value){
        super.setAttribute('canonical', value);
    }

}