export default class PvApiResponse {
    constructor(response) {
        this._httpCode = response.status;
        this._headers = response.headers;
        this._data = response.data;
    }

    get httpCode() {
        return this._httpCode
    }

    get headers() {
        return this._headers
    }

    get data() {
        return this._data
    }

    getHeader(key) {
        if (key === undefined || key === null || !this._headers.hasOwnProperty(key))
            return null;
        return this._headers[key];
    }

    getHeaders() {
        return this._headers;
    }

}