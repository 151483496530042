<template lang="pug">
  .locations-page
    section.head.padding-t-0
      section.breadcrumbs
        .container-custom
          .row
            .col-md-12
              ul.breadcrumbs
                li
                  router-link(to="/" tag="a") Головна
                li Наші локації
      .container-custom
        .row.justify-center
          .col-12
            h2.section-head.section-head-dark.text-center
              span Наші
              |  локації
          .col-12.location-content-wrap(v-for="(location, index) in locations" v-if="locations.length > 0")
            .row
              .col-lg-5.col-12.padding-0
                slick(ref="locationsImagesSlider" :options="locationsImagesSliderOptions" @afterChange="locationsSliderAfterChange").location-images-slider
                  img(:src="location.image")
              .col-lg-7.col-12.padding-0
                .location-wrap
                  .location
                    .location-name {{ location.head }}
                    .location-description {{ location.description }}
                    .location-features
                      .location-feature(v-for="feature in location.feature")
                        .location-feature-icon
                          img(src="../img/home/service-3-icon.png")
                        .location-feature-name {{ feature }}
          template(v-else)
            .col-md-12.col-lg-6.text-center
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: "Locations",
  components: {Slick},
  data() {
    return {
      locationsImagesSliderOptions: {
        slidesToShow: 1
      },
    }
  },
  methods: {
    locationsSliderAfterChange(event, slick, currentSlide) {

    },
  },
  computed: {
    locations() {
      return this.$store.getters.locations
    },
  },
  async mounted() {
    if(this.$store.getters.locations.length === 0)
      await this.$store.dispatch('getLocations')
  }
}
</script>