import PageModel from "../sdk/classes/models/pageModel";

export default {
    state: {
        locationsPage: {
            locations: []
        }
    },
    getters: {
        locations(state) {
            return state.locationsPage.locations
        },
    },
    mutations: {
        loadLocations(state, payload) {
            payload.forEach((cv) => {
                if(cv.value[0] === '[')
                    state.locationsPage[cv.name] = JSON.parse(cv.value)
                else
                    state.locationsPage[cv.name] = cv.value
            })
        },
    },
    actions: {
        async getLocations ({commit}) {
            try{
                let page = await PageModel.getById(5);
                commit('loadLocations', page.options)
            } catch (e) {
                console.log(e)
            }

        },
    }
}