<template lang="pug">
  .service-page
    section.head.padding-0(v-if="servicesCategory")
      section.breadcrumbs
        .container-custom
          .row
            .col-md-12
              ul.breadcrumbs
                li
                  router-link(to="/" tag="a") Головна
                li  {{ servicesCategory.name }}
      section.content.padding-t-0
        .container-custom
          .row.justify-between
            .col-12
              h2.section-head.section-head-dark.main.text-center
                template(v-for="(word, index) in servicesCategory.name.split(' ')")
                  template(v-if="index + 1 < servicesCategory.name.split(' ').length / 2")  {{ word }}
                  template(v-else)
                    span  {{ word }}
            .col-12
              p(v-html="servicesCategory.description")
    template(v-if="servicesInCategory && servicesInCategory !== 'empty'")
      section.services-head
        .container
          .row
            .col-12
              h2.section-head.section-head-dark.light.text-center Наші
                span  послуги
      section.services-list(v-if="servicesInCategory.length > 0")
        .container-custom
          .row.justify-center.services-slider
            .col-md-12.col-lg-6(v-for="service in servicesInCategory")
              .service-wrap.padding-l-15.padding-r-15
                .row
                  .col-12
                    p.service-name.text-center {{ service.name }}
                  .col-md-6
                    .service-img-wrap
                      img(:src="service.photo").service-img
                      .service-icon
                        //img(src="../../img/home/service-1-icon.png")
                        img(src="../../img/footer-time.png")
                        | {{ service.doingTime }} хвилин
                    //a(:href="service.link" v-if="service.link").btn.text-center Записатися
                    a(href="https://client.appointer.com.ua/uk/widget?for=ato-sentr").btn.text-center Записатися
                  .col-md-6
                    p.service-description(v-html="service.description")
      section.services-list(v-else)
        .container
          .row.justify-center.text-center
            v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    template(v-if="servicesCategory && workersInCategory !== 'empty'")
      section.team(v-if="workersInCategory.length > 0")
        .container
          .row
            .col-12
              h2.section-head.section-head-dark.light.text-center Наша
                span  команда
        .container-custom
          .row.justify-center
            .col-12
              slick(ref="teamSlider" :options="teamSliderOptions" ).team-slider
                template(v-for="worker in workersInCategory")
                  .team-slide.d-flex.padding-l-15.padding-r-15
                    .row
                      .col-12
                        p.team-member-name {{ worker.fullName }}
                        .team-member-head {{ worker.position }}
                      .col-md-6
                        .team-member-img-wrap
                          img(:src="worker.photo").team-member-img
                          .team-member-icon
                            img(src="../../img/home/service-1-icon.png")
                        //a(:href="worker.linkToRecord" v-if="worker.linkToRecord !== null && worker.linkToRecord.length > 0").btn.text-center Записатися
                        a(href="https://client.appointer.com.ua/uk/widget?for=ato-sentr").btn.text-center Записатися
                      .col-md-6
                        .team-member-description
                          .team-member-specialization-description
                            .team-member-head Спеціалізація:
                            p {{ worker.specialty }}
                          .team-member-services-description(v-if="worker.servicesBlock !== null && JSON.parse(worker.servicesBlock).length > 0 ")
                            .team-member-head Послуги:
                            ul
                              li(v-for="service in JSON.parse(worker.servicesBlock)") {{ service }}
      section.team(v-else)
        .container
          .row.justify-center.text-center
            v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    //section.galery
        .container-custom
            .row
                .col-12
                    h2.section-head.text-center Галерея
            .row
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-1.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-2.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-3.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-4.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-1.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-2.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-3.png")
                .col-lg-3.col-md-4.col-sm-6.col-12
                    img(src="../../img/action-text-image-4.png")
</template>

<script>
import Slick from "vue-slick";

export default {
  name: "Service",
  props: ['url'],
  components: {Slick},
  data() {
    return {
      teamSliderOptions: {
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: '<i class="fas fa-angle-left"></i>',
        nextArrow: '<i class="fas fa-angle-right"></i>',
        swipe: false,
        draggable: false,
        //autoplay: 2000,
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
              draggable: true,
            }
          }
        ]
      },
      rating: 2.5,
      services: [],
      emptyWorkers: false
    }
  },
  beforeUpdate() {
    if (this.$refs.teamSlider) {
      this.$refs.teamSlider.destroy();
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.teamSlider) {
        this.$refs.teamSlider.create(this.teamSliderOptions);
      }
    });
  },
  computed: {
    servicesCategory() {
      return this.$store.getters.categoryByUrl(this.url)
    },
    servicesInCategory() {
      let services = [],
          allServices = this.$store.getters.services
      allServices.forEach((item) => {
        if(item.serviceGroupId === this.servicesCategory.id)
          services.push(item)
      })
      if(services.length > 0)
        return services
      else
        return 'empty'
    },
    workersInCategory() {
      let workers = [],
          allWorkers = this.$store.getters.workers
      allWorkers.forEach((item) => {
        if(item.serviceGroupId === this.servicesCategory.id && item.show === 1)
          workers.push(item)
      })
      if(workers.length === 0)
        return 'empty'
      else
        return workers
    },
  },
  async mounted() {
    if(this.$store.getters.services.length === 0)
      await this.$store.dispatch('getServices')
    if(this.$store.getters.workers.length === 0)
      await this.$store.dispatch('getWorkers')
  }
}
</script>