<template lang="pug">
  .about(v-if="about")
    section.head
      section.breadcrumbs
        .container-custom
          .row
            .col-md-12
              ul.breadcrumbs
                li
                  router-link(to="/" tag="a") Головна
                li Про нас
      .container-custom
        .row
          .col-12
            h2.section-head.section-head-dark.text-center Про
              span  нас
        .row.align-items-center
          .col-md-6.col-md-6
            p.director-description {{ about.director.description }}
          .col-md-6.col-md-6
            .director
              .director-name {{ about.director.name }}
              img(:src="about.director.image").director-photo
              .director-position {{ about.director.position }}
              .director-signature
                img(:src="about.director.signature")
    section.about-head
      .container
        .row
          .col-12
            h2.section-head.section-head-dark.light.text-center Наша
              span  історія
    section.history
      .container-custom
        .row.justify-center
          .col-12
            slick(:options="historySliderOptions").history-slider
              .history-block(v-for="block in about.history")
                .history-block-date
                  | {{ block.year }}
                  //template(v-if="block.month.length > 0")
                      br
                      | {{ block.month }}
                .history-block-sub-head {{ block.name }}
                .history-block-text {{ block.description }}
          .col-12
            .history-arrows
          .col-lg-7.col-md-10.d-none.d-lg-block
            slick(:options="historyYearsSliderOptions").history-slider-year
              .year.d-flex(v-for="block in about.history") {{ block.year }}

    section.team(v-if="Object.keys(workers).length > 0")
      .container-custom(v-for="(item, key) in workers")
        .row
          .col-12
            h2.section-head.section-head-dark.light.text-center {{ key }}
        .row.justify-center
          .col-12.col-lg-12.col-md-12
            slick(:options="teamSliderOptions").team-slider
              .team-slide.d-flex.padding-l-15.padding-r-15(v-for="worker in item")
                .row
                  .col-12
                    p.team-member-name {{ worker.fullName }}
                    .team-member-head {{ worker.position }}
                  .col-md-6
                    .team-member-img-wrap
                      img(:src="worker.photo").team-member-img
                      .team-member-icon
                        img(src="../img/home/service-1-icon.png")
                    a(href="https://client.appointer.com.ua/uk/widget?for=ato-sentr").btn.text-center Записатися
                  .col-md-6
                    .team-member-description
                      .team-member-specialization-description
                        .team-member-head Спеціалізація:
                        p {{ worker.specialty }}
                      .team-member-services-description(v-if="worker.servicesBlock !== null && JSON.parse(worker.servicesBlock).length > 0 ")
                        .team-member-head Послуги:
                        ul
                          li(v-for="service in JSON.parse(worker.servicesBlock)") {{ service }}
    section.team(v-else)
      .container
        .row.justify-center.text-center
          v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    //section.team
      .container-custom(v-for="team in teams")
        .row
          .col-12
            h2.section-head.section-head-dark.light.text-center {{ team.name }}
        .row.justify-center
          .col-12.col-lg-12.col-md-12
            slick(:options="teamSliderOptions").team-slider
              .team-slide.d-flex.padding-l-15.padding-r-15(v-for="worker in team.workers")
                .row
                  .col-12
                    p.team-member-name {{ worker.name }}
                    .team-member-head {{ worker.head }}
                  .col-md-6
                    .team-member-img-wrap
                      img(src="../img/team-1-1.jpg" v-if="worker.img === '1-1'").team-member-img
                      img(src="../img/team-1-2.jpg" v-if="worker.img === '1-2'").team-member-img
                      img(src="../img/team-2-1.jpg" v-if="worker.img === '2-1'").team-member-img
                      img(src="../img/team-2-2.jpg" v-if="worker.img === '2-2'").team-member-img
                      img(src="../img/team-3-1.jpg" v-if="worker.img === '3-1'").team-member-img
                      img(src="../img/team-3-2.jpg" v-if="worker.img === '3-2'").team-member-img
                      img(src="../img/team-3-3.jpg" v-if="worker.img === '3-3'").team-member-img
                      img(src="../img/team-3-4.jpg" v-if="worker.img === '3-4'").team-member-img
                      img(src="../img/team-3-5.jpg" v-if="worker.img === '3-5'").team-member-img
                      img(src="../img/team-3-6.jpg" v-if="worker.img === '3-6'").team-member-img
                      img(src="../img/team-3-7.jpg" v-if="worker.img === '3-7'").team-member-img
                      img(src="../img/team-4-1.jpg" v-if="worker.img === '4-1'").team-member-img
                      img(src="../img/team-4-2.jpg" v-if="worker.img === '4-2'").team-member-img
                      .team-member-icon
                        img(src="../img/home/service-1-icon.png")
                    a(:href="worker.link" v-if="worker.link.length > 0").btn.text-center Записатися
                  .col-md-6
                    .team-member-description
                      .team-member-specialization-description
                        .team-member-head Спеціалізація:
                        p {{ worker.specialization }}
                      .team-member-services-description
                        .team-member-head Послуги:
                        ul
                          li(v-for="service in worker.services") {{ service }}
  .about(v-else)
    section.head
      section.breadcrumbs
        .container-custom
          .row.justify-center
            .col-md-12.col-lg-6
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>
import Slick from "vue-slick";
import PageModel from "../sdk/classes/models/pageModel";

export default {
  name: "About",
  components: {Slick},
  data() {
    return {
      teamSliderOptions: {
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: '<i class="fas fa-angle-left"></i>',
        nextArrow: '<i class="fas fa-angle-right"></i>',
        swipe: false,
        draggable: false,
        autoplay: 2000,
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe: true,
              draggable: true,
            }
          }
        ]
      },
      historyYearsSliderOptions: {
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        focusOnSelect: true,
        swipe: false,
        draggable: false,
        arrows: false,
        asNavFor: '.history-slider'
      },
      historySliderOptions: {
        appendArrows: '.history-arrows',
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<i class="fas fa-angle-left"></i>',
        nextArrow: '<i class="fas fa-angle-right"></i>',
        swipe: false,
        draggable: false,
        adaptiveHeight: true,
        asNavFor: '.history-slider-year',
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 1,
              swipe: true,
              draggable: true,
            }
          }
        ]
      },
      historyBlocks: [
        {
          name: 'Підзаголовок',
          text: 'Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.',
          year: 2016,
          month: 'Серпень'
        },
        {
          name: 'Підзаголовок',
          text: 'Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.',
          year: 2017,
          month: 'Серпень'
        },
        {
          name: 'Підзаголовок',
          text: 'Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.',
          year: 2018,
          month: 'Серпень'
        },
        {
          name: 'Підзаголовок',
          text: 'Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.',
          year: 2019,
          month: 'Серпень'
        },
        {
          name: 'Підзаголовок',
          text: 'Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Тут команда фахівців працює з ветеранами, військовими, курсантами, поліцейськими, рятувальниками та членами їх родин. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ. Ми створили простір для ветеранів та громадських організацій, що працюють у сфері ветеранських справ.',
          year: 2020,
          month: 'Серпень'
        },
      ],
      about: null,
      teams: [
        {
          name: 'Відділ соціальної допомоги',
          workers: [
            {
              name: 'Саган Вікторія Олександрівна',
              img: '3-1',
              head: 'Спеціаліст з соціальної роботи',
              specialization: 'Кадровий облік та документообіг',
              link: 'https://forms.gle/QL2x4rdb3J1BWbcL8',
              services: [
                'Первинна консультація нових клієнтів центру',
                'Консультації з кадрового забезпечення',
                'Інспектор з кадрів.'
              ],
            },
            {
              name: 'Діхтяр Лариса Іванівна',
              img: '3-4',
              head: 'Завідувач відділення соціальної адаптації',
              specialization: 'консультант з питань соціальної адаптації',
              link: 'https://forms.gle/QL2x4rdb3J1BWbcL8',
              services: [
                'створює ефективні форми проведення соціально-адаптаційних заходів та занять;',
                'організовує визначення індивідуальних потреб відвідувачів у соціальних послугах;',
                'організовує надання допомоги відвідувачам відділення;',
                'контролює формування особових справ громадян;',
                'організовує роботу гуртків та груп самодопомоги, які діють при віділенні соціальної адаптації.',
              ],
            },
            {
              name: 'Земляков Артем Дмитрович',
              img: '3-6',
              head: 'Фахівець з професійної переорієнтації та розвитку власної справи',
              specialization: 'Професійна переорієнтація та розвиток власної справи',
              link: 'https://forms.gle/3ihAheou5u4Mz4u5A',
              services: [
                'Консультації з питань професійної переорієнтації;',
                'Консультації по навчанню для відкриття власної справи;',
                'Проведення профорієнтаційної діагностики;',
                'Організація підготовки, перепідготовки та підвищення кваліфікації;'
              ],
            },
          ]
        },
        {
          name: 'Відділ психологічної допомоги',
          workers: [
            {
              name: 'Бабіна Ганна Олександрівна',
              img: '1-1',
              head: 'Практичний психолог',
              specialization: 'Когнітивно-поведінкова психотерапія',
              link: 'https://forms.gle/3ihAheou5u4Mz4u5A',
              services: [
                'Консультації з питань подолання  депресії, стресу, травми, тривоги',
                'Робота з подолання алкогольної та наркотичної залежності.',
                'Проведення соціально-психологічних тренінгів.'
              ],
            },
            {
              name: 'Ольшевська Олеся Олександрівна',
              img: '1-2',
              head: 'Завідувач відділення психологічної реабілітації та підтримки\n',
              specialization: 'АРТ терапія, Когнітивно поведінкова психотерапія',
              link: 'https://forms.gle/3ihAheou5u4Mz4u5A',
              services: [
                'Консультації з питань сімейної, дитячої, вікової та клінічної психології',
                'Робота по подоланню неврозів, тривоги, страхів, панічних атак, неконтрольованої агресії та інше',
                'Забезпечення психологічної підтримки у кризових станах, з метою адаптації до умов життя',
                'Навчання з навичок саморегуляції',
              ],
            },
          ]
        },
        {
          name: 'Відділ профорієнтації',
          workers: [
            {
              name: 'Кузьменко Надія Михайлівна',
              img: '3-2',
              head: 'Завідувач відділення професійної переорієнтації та розвитку власної справи\n',
              specialization: 'Оптимізація професійного самовизначення з врахування особистісних характеристик та потреб ринку праці.',
              link: 'https://forms.gle/drCDwAygK54uXcXL9',
              services: [
                'організація підготовки, перепідготовки та підвищення кваліфікації клієнтів центру',
                'сприяння у навчанні для відкриття власної справи клієнтів',
                'організація професійної орієнтації'
              ],
            },
            /*{
              name: 'Кулик Маргарита Валентинівна',
              img: '3-3',
              head: 'Фахівець з професійної переорієнтації та розвитку власної справи',
              specialization: 'Професійна переорієнтація та розвиток власної справи',
              link: 'https://forms.gle/drCDwAygK54uXcXL9',
              services: [
                'Консультації з питань професійної переорієнтації;',
                'Консультації по навчанні для  відкриття власної справи',
                'участь в організації підготовки, перепідготовки та підвищення кваліфікації',
                'консультування з питань проф. переорієнтації',
                'сприяє організації семінарів та тренінгів відділу'
              ],
            },*/
            {
              name: 'Гнатюк Вероніка Василівна',
              img: '3-7',
              head: 'Фахівець з професійної переорієнтації та розвитку власної справи',
              specialization: 'Консультант з питань професійної переорієнтації',
              link: 'https://forms.gle/drCDwAygK54uXcXL9',
              services: [
                'Консультації з питань професійної переорієнтації;',
                'Консультації по навчанню для відкриття власної справи;',
                'Проведення профпереорієнтаційної діагностики;',
                'Організація підготовки , перепідготовки та підвищення кваліфікації'
              ],
            },
          ]
        },
        {
          name: 'Юридичний відділ',
          workers: [
            {
              name: 'Кравчук Юлія Олександрівна',
              img: '2-1',
              head: 'Юрисконсульт провідний',
              specialization: 'Кримінальне, цивільне та адміністративне право. Адвокат.',
              link: 'https://forms.gle/JSGgfT2bfKdwQ6859',
              services: [
                'Консультування та надання інших  юридичних послуг.'
              ],
            },
            {
              name: 'Садовий Олександр Валентинович',
              img: '2-2',
              head: 'Юрист',
              specialization: 'Цивільне, господарське, адміністративне право.',
              link: 'https://forms.gle/JSGgfT2bfKdwQ6859',
              services: [
                'Надання первинної правової допомоги',
                'Консультування та надання інших юридичних послуг'
              ],
            },
          ]
        },
        {
          name: 'Зона здоров`я',
          workers: [
            {
              name: 'Муха Ольга Миколаївна',
              img: '4-1',
              head: '',
              specialization: 'Фізична реабілітація',
              link: '',
              services: [
                'Забезпечує проведення фізкультурно-оздоровчих, рекреаційних, реабілітаційних  видів занять у тренажерному залі.'
              ],
            },
            {
              name: 'Данілова Оксана Юріївна',
              img: '4-2',
              head: '',
              specialization: 'Фізична реабілітація',
              link: '',
              services: [
                'Забезпечує проведення фізкультурно-оздоровчих, рекреаційних, реабілітаційних  видів занять у тренажерному залі.'
              ],
            },
          ]
        },
      ]
    }
  },
  computed: {
    workers() {
      let workers = {},
          allWorkers = this.$store.getters.workers
      allWorkers.forEach((item) => {
        if(workers.hasOwnProperty(item.category))
          workers[item.category].push(item)
        else
          workers[item.category] = [item]
      })
      return workers
    },
  },
  async mounted() {
    let getActionsPage = async function (id) {
      try {
        let page = await PageModel.getById(id);
        let options = {};
        page.options.forEach((cv) => {
          if (cv.value[0] === '[' || cv.value[0] === '{')
            options[cv.name] = JSON.parse(cv.value)
          else
            options[cv.name] = cv.value
        })
        return options
      } catch (e) {
        console.log(e)
      }
    }
    this.about = await getActionsPage(6)
    //this.$store.commit('setContactsOptions', this.abount)
    if (this.$store.getters.workers.length === 0)
      await this.$store.dispatch('getWorkers')
  }
}
</script>

<style>

</style>