<template lang="pug">
    .single-news-page
        section.content-wrap(v-if="newsContent")
            section.breadcrumbs
                .container-custom
                    .row
                        .col-md-12
                            ul.breadcrumbs
                                li
                                    router-link(to="/" tag="a") Головна
                                li
                                    router-link(:to="{name: 'NewsList'}" tag="a") Новини
                                li {{ newsContent.name }}
            section.content.padding-t-0
                .container-custom
                    .row
                        .col-lg-8.col-md-12.col-12
                            .news-card
                                .news-card-name {{ newsContent.name }}
                                v-img(:src="newsContent.image" max-height="668")
                                //.news-card-category test
                                //.news-card-info
                                    p.author test
                                    p.date 10.10.20
                            .news-content
                                p(v-html="newsContent.content")
                        .col-lg-4.col-md-12.col-12
                            .row.justify-center
                                .col-lg-12.col-md-6.col-12
                                    news-subscribe
                                .col-12
                                    .row.justify-center
                                        template(v-for="(item, index) in moreNews")
                                            .col-lg-12.col-md-6.col-12(v-if="index < 3 && item.url !== url")
                                                router-link(tag="div" :to="`/news/${item.url}`").news-card.more
                                                    .news-card-name {{ item.name }}
                                                    v-img(:src="item.image" height="100%")
                                                    //.news-card-category {{ item.category }}
                                                    //.news-card-info
                                                        p.author {{ item.author }}
                                                        p.date {{ item.date }}

</template>

<script>
    import NewsSubscribe from "../Shared/NewsSubscribe";
    import NewsController from "../../sdk/classes/controllers/apiNewsController";

    export default {
        name: "NewsSingle",
        props: ['url'],
        components: {
            NewsSubscribe
        },
        data() {
            return {
                news: [
                    {
                        id: 2,
                        name: 'Важливе свято для нашої країни - День Соборностi України!',
                        author: 'Имя Автора',
                        category: 'Рубрика',
                        date: '10.11.20'
                    },
                    {
                        id: 3,
                        name: 'Важливе свято для нашої країни - День Соборностi України!',
                        author: 'Имя Автора',
                        category: 'Рубрика',
                        date: '10.11.20'
                    },
                    {
                        id: 4,
                        name: 'Важливе свято для нашої країни - День Соборностi України!',
                        author: 'Имя Автора',
                        category: 'Рубрика',
                        date: '10.11.20'
                    },
                ]
            }
        },
        computed: {
            newsContent() {
                return this.$store.getters.newsUrl(this.url)
            },
            moreNews() {
                return this.$store.getters.newsText
            }
        },
        async mounted() {
            let getNewsList = async function() {
                try{
                    let news = await (new NewsController).getAll();
                    let newsList = news.data;
                    newsList.forEach(function (item) {
                        if(item.youtube !== null)
                            item['play'] = false
                    })
                    return newsList
                } catch (e) {
                    console.log(e)
                }
            }

            let newsList = await getNewsList()
            this.newsServer = newsList
            this.$store.commit('loadNews', newsList)
            this.action = newsList.find(news => news.url === this.url)
        }
    }
</script>