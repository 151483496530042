import ApiController from './base/apiController'
import PageModel from "../models/pageModel";

export default class PvApiPageController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'pages';
        this._modelClass = PageModel;
    }
}