import PageModel from "../sdk/classes/models/pageModel";
import EventController from "../sdk/classes/controllers/apiEventController";

export default {
    state: {
        actionsPage: {
            name: '',
            description: ''
        },
        actions: []
    },
    getters: {
        actionsPage(state) {
            return state.actionsPage
        },
        actions(state) {
            return state.actions
        },
        actionUrl(state) {
            return url => {
                if(state.actions !== null)
                    return state.actions.find(action => action.url === url)
            }
        }
    },
    mutations: {
        loadActionsPage(state, payload) {
            payload.forEach((cv) => {
                if(cv.value[0] === '[')
                    state.actionsPage[cv.name] = JSON.parse(cv.value)
                else
                    state.actionsPage[cv.name] = cv.value
            })
        },
        loadActions(state, payload) {
            state.actions = payload
        },
    },
    actions: {
        async getActionsPage ({commit}) {
            try{
                let page = await PageModel.getById(4);
                commit('loadActionsPage', page.options)
            } catch (e) {
                console.log(e)
            }

        },
        async getActions ({commit}) {
            try{
                let events = await (new EventController).getAll();
                commit('loadActions', events)
            } catch (e) {
                console.log(e)
            }

        },
    }
}