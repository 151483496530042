<template lang="pug">
  section.contacts-section-wrap.padding-b-0
    .container
      .row
        .col-12
          h2.section-head.section-head-dark.green-span.text-center
            span Наші
            |  контакти
    .contacts
      .container(v-if="Object.keys(contacts).length > 0")
        .row
          .contacts-wrap.col-12
            .row.align-center
              .col-lg-6.col-12.address-wrap
                p.section-sub-head.dark Адреса та телефони
                .contacts-list
                  .contacts-list-item
                    img(src="../../img/footer-location.png")
                    .contacts-list-item-text
                      p(v-for="address in contacts.address") {{ address }}
                  .contacts-list-item
                    img(src="../../img/footer-phone.png")
                    .contacts-list-item-text
                      a(:href="`tel:${phone}`" v-for="phone in contacts.phones") {{ phone }}
                  .contacts-list-item
                    img(src="../../img/footer-time.png")
                    .contacts-list-item-text
                      p {{ contacts.time }}
                  .contacts-list-item
                    img(src="../../img/footer-mail.png")
                    .contacts-list-item-text
                      a(:href="`mailto:${contacts.email}`") {{ contacts.email }}
                  .contacts-list-item
                    img(src="../../img/footer-location.png")
                    .contacts-list-item-text
                      a(:href="contacts.gmaps" target="_blank") Ми на карті
                .social
                  a(:href="social.link" v-for="social in contacts.socials")
                    i.fab.fa-facebook-f(v-if="social.name === 'Facebook'")
                    i.fab.fa-instagram(v-if="social.name === 'Instagram'")
                    i.fab.fa-viber(v-if="social.name === 'Viber'")
                    i.fab.fa-telegram-plane(v-if="social.name === 'Telegram'")
              .col-lg-6.col-12
                .form
                  p.section-sub-head.dark.text-center
                    span Зворотній
                    |  зв'язок
                  v-text-field(label="Введіть ваше ім'я" v-model="form.name" color="#90D21F" hide-details)
                  v-text-field(label="Введіть ваш e-mail" v-model="form.email" color="#90D21F" hide-details)
                  v-text-field(label="Введіть текст вашого звернення" v-model="form.message" color="#90D21F" hide-details)
                  button.btn.block-center надіслати
      .container(v-else)
        .row.justify-center
          v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>

export default {
  name: "ContactsBlock",
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      }
    }
  },
  computed: {
    contacts() {
      return this.$store.getters.contactsOptions
    }
  },
  methods: {
    send() {
      console.log(this.form)
    }
  },
  async mounted() {
    await this.$store.dispatch('getContacts')
  }
}
</script>