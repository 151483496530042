<template lang="pug">
  div.main
    section.head
      .container-full(v-if="headSlider.length > 0")
        .row
          .col-12.padding-0
            slick(ref="headSlider" :options="headSliderOptions" @init="headSliderInit" @afterChange="headSliderAfterChange").head-slider
              .head-slide(v-for="(slide, index) in headSlider" :key="index"  :style="{backgroundImage: `url(${slide.image})`}")
                .container
                  .row
                    .col-12
                      p.head-slide-head
                        template(v-for="(word, index) in slide.head.split(' ')")
                          template(v-if="index + 1 !== slide.head.split(' ').length")  {{ word }}
                          template(v-else)
                            span  {{ word }}
                      p.head-slide-sub-head(v-html="slide.subHead")

            .head-slider-progress.d-lg-flex.d-none
              p.current-slide(ref="headSliderProgressNumber") 01
              .progress
                .current(ref="headSliderProgress")
              p.total-slide
                template(v-if="headSlider.length < 10") 0{{ headSlider.length }}
                template(v-else) {{ headSlider.length }}
            .with-support-slider-wrap
              .container
                .row
                  .col-12
                    p За підтримки:
                    .with-support-slider(v-if="partners.length > 0")
                      a(v-for="partner in partners" :href="partner.link")
                        img(:src="partner.image" )
      .container.load(v-else)
        .row.justify-center.text-center
          v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    section.about
      template(v-if="aboutBlocks.length > 0")
        .container-fluid.d-lg-block.d-none
          .row
            .col-lg-8
            .col-lg-4
              .about-image-slider-main-wrap
                .about-image-slider-wrap
                  slick(ref="aboutImageSlider" :options="aboutImageSlidesOptions" @init="aboutSliderInit" @afterChange="aboutSliderAfterChange").about-image-slider
                    img(:src="block.image" v-for="block in aboutBlocks").about-image-slide
                  .about-slider-progress
                    .progress.horizontal
                      .current(ref="aboutSliderProgress")
        .container
          .row
            .col-lg-8
              h2.section-head Про нас
              slick(ref="aboutSlider" :options="aboutSlidesOptions").about-slider
                .about-slide(v-for="(block, index) in aboutBlocks")
                  img(:src="block.image").about-image-slide.d-block.d-lg-none
                  img(src="../img/home/waves-about-slider-1.png").d-none.d-lg-block
                  p.about-slide-head {{ block.head }}
                  p.about-slide-text {{ block.subHead }}

              .about-arrows
            .col-lg-4
      .container(v-else)
        .row.justify-center
          v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    section.subscribe
      .container
        .row
          .col-lg-8
            h2.section-head.light Приєднуйтесь
            p.section-sub-head.light до нас
          .col-lg-4
            p.section-sub-head.light Прийдіть просту реєстрацію прямо на сайті
            button.btn приєднатися
    section.padding-0.waves-bg
      section.services
        .container
          .row
            .col-12
              h2.section-head Наші послуги
          .row(v-if="servicesCategories")
            .col-lg-6.col-md-6.col-sm-6.col-xs-6.col-12(v-for="service in servicesCategories")
              .service
                .service-img-wrap
                  img(:src="service.photo").service-img
                  .service-icon
                    img(src="../img/home/service-1-icon.png")
                .service-description
                  p.service-name {{ service.name }}
                  .service-description-wrap
                    p.service-description-text(v-html="service.description.slice(0, 150) + '...'" v-if="service.description.length > 150")
                    p.service-description-text(v-html="service.description" v-else)
                    router-link(:to="`/service/${service.url}`" tag="a" class="btn") Докладніше
                      i.fas.fa-arrow-right
          .row.justify-center.text-center(v-else)
            v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
          //.row
            .col-lg-6.col-md-6.col-sm-6.col-xs-6.col-12(v-for="service in services")
              .service
                .service-img-wrap
                  img(:src="service.image").service-img
                  .service-icon
                    img(src="../img/home/service-1-icon.png")
                .service-description
                  p.service-name {{ service.name }}
                  .service-description-wrap
                    p.service-description-text {{ service.description }}
                      span  ...
                    router-link(:to="`/service/${service.link}`" tag="a" class="btn") Докладніше
                      i.fas.fa-arrow-right
      section.locations
        .container-fluid.d-lg-block.d-none
          .row(v-if="locations.length > 0")
            .col-lg-5.padding-0
              .locations-image-slider-main-wrap
                .locations-image-slider-wrap
                  slick(ref="locationsImagesSlider" :options="locationsImagesSliderOptions" @init="locationsSliderInit" @afterChange="locationsSliderAfterChange").locations-image-slider
                    img(:src="location.image" v-for="location in locations").locations-image-slide
                  .locations-slider-progress
                    .progress.horizontal(ref="locationsSliderProgressWrap")
                      .current
        .container
          .row(v-if="locations.length > 0")
            .col-lg-3
            .col-lg-9
              .section-head.text-right Наші локації
              slick(ref="locationsSlider" :options="locationsSliderOptions").locations-slider
                .locations-slide(v-for="(location, index) in locations")
                  img(:src="location.image").locations-image-slide.d-lg-none.d-block
                  p.locations-slide-name {{ location.head }}
                  p.locations-slide-text {{ location.description }}
                  router-link(to="/locations" tag="a").btn докладніше
                    i.fas.fa-arrow-right
          .row.justify-center(v-else)
            .col-md-12.col-lg-12.text-center
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    resent-actions
    contacts-block


</template>

<script>
import Slick from "vue-slick";
import ResentActions from "./Shared/ResentActions";
import ContactsBlock from "./Shared/ContactsBlock";
import PageModel from "../sdk/classes/models/pageModel";

export default {
  name: 'Home',
  components: {
    Slick,
    ResentActions,
    ContactsBlock
  },
  data() {
    return {
      headBgs: [
        'head-slide-1.jpg',
        'head-slide-2.jpg',
        'head-slide-3.jpg',
        'head-slide-4.jpg',
        'head-slide-5.jpg'
      ],
      headSliderOptions: {
        arrows: false,
        autoplay: true,
        adaptiveHeight: true,
        autoplaySpeed: 5000,
      },
      aboutSlidesOptions: {
        prevArrow: '<i class="fas fa-angle-left"></i>',
        nextArrow: '<i class="fas fa-angle-right"></i>',
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 2,
        asNavFor: '.about-image-slider',
        appendArrows: '.about-arrows',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 1,
              adaptiveHeight: true,
              autoplay: false,
            }
          }
        ]
      },
      aboutImageSlidesOptions: {
        arrows: false,
        adaptiveHeight: true,
        asNavFor: '.about-slider',
        swipe: false,
        draggable: false
      },
      services: [
        {
          name: 'Соціальна допомога',
          description: 'Сьогодні одним із найважливіших завдань для працівників нашого центру  є надання соціальної підтримки, супроводу',
          link: '1',
          image: 'servise-group-1.jpg'
        },
        {
          name: 'Психологічна допомога',
          description: 'Надзвичайна ситуація на сході країни призводять до психологічних травм її учасників,що може проявитися у розладах',
          link: '2',
          image: 'servise-group-2.jpg'
        },
        {
          name: 'Профорієнтація',
          description: 'Професійна адаптація військовослужбовців до умов життя в цивільному середовищі  це – суспільний процес їх',
          link: '3',
          image: 'servise-group-3.jpg'
        },
        {
          name: 'Юридичне консультування',
          description: 'Юридичне консультування – це кваліфікована правова допомога фахівця, який допоможе вам знайти вихід зі складної ситуації',
          link: '4',
          image: 'https://cdn.pixabay.com/photo/2017/06/09/22/56/lady-justice-2388500_960_720.jpg'
        },
      ],
      locationsImagesSliderOptions: {
        arrows: false,
        asNavFor: '.locations-slider',
        swipe: false,
        draggable: false
      },
      locationsSliderOptions: {
        asNavFor: '.locations-image-slider',
        prevArrow: '<i class="fas fa-angle-left"></i>',
        nextArrow: '<i class="fas fa-angle-right"></i>',
        adaptiveHeight: true,
      },
      actionsSliderOptions: {
        slidesToShow: 2,
        variableWidth: true,
        arrows: false,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              initialSlide: 0,
            }
          },
          {
            breakpoint: 500,
            settings: {
              initialSlide: 0,
              variableWidth: false,
              slidesToShow: 1,
              adaptiveHeight: true,
              dots: true
            }
          }
        ]
      },
      actionsActiveSliderOptions: {
        arrows: false,
        swipe: false,
        draggable: false
      },
      locationsPage: null
    }
  },
  computed: {
    headSlider() {
      return this.$store.getters.headSlider
    },
    aboutBlocks() {
      return this.$store.getters.aboutBlocks
    },
    partners() {
      return this.$store.getters.partnersHome
    },
    locations() {
      return this.$store.getters.locations
    },
    servicesCategories() {
      return this.$store.getters.servicesCategories
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  methods: {
    headSliderAfterChange(event, slick, currentSlide) {
      let index = currentSlide + 1
      let numberHtml = ''

      if (index < 10) {
        numberHtml = `0${index}`
      } else {
        numberHtml = index.toString()
      }
      this.$refs.headSliderProgressNumber.innerHTML = numberHtml
      this.$refs.headSliderProgress.style.height = index / this.headSlider.length * 100 + "%"
    },

    headSliderInit() {
      this.$refs.headSliderProgress.style.height = 1 / this.headSlider.length * 100 + "%"
    },

    aboutSliderInit() {
      this.$refs.aboutSliderProgress.style.width = 1 / this.aboutBlocks.length * 100 + "%"

      let aboutImages = document.querySelectorAll('.about-image-slider-wrap .about-image-slide');
      let aboutImageHeight = document.getElementsByClassName('about')[0].offsetHeight - 145 - 145 - 52
      aboutImages.forEach((element) => {
        element.style.height = aboutImageHeight + "px"
      })
    },

    aboutSliderAfterChange(event, slick, currentSlide) {
      this.$refs.aboutSliderProgress.style.width = (currentSlide + 1) / this.aboutBlocks.length * 100 + "%"
    },

    locationsSliderInit() {
      /*console.log('locationsSliderInit')
      let locationsImages = document.querySelectorAll('.locations-image-slider .locations-image-slide');
      let locationsWrapHeight = document.querySelectorAll('section.locations')[0].offsetHeight - 76 - 145
      locationsImages.forEach((element) => {
        element.style.height = locationsWrapHeight + 54 + "px"
      })*/
    },

    locationsSliderAfterChange() {
      let locationsImages = document.querySelectorAll('.locations-image-slider .locations-image-slide');
      let locationsWrapHeight = document.querySelectorAll('section.locations')[0].offsetHeight - 76 - 145
      locationsImages.forEach((element) => {
        element.style.height = locationsWrapHeight + 54 + "px"
      })
    },

    actionsSliderSwipe(event, slick, direction) {
      if (direction === 'left') {
        this.$refs.actionsActiveSlider.next()
      }
      if (direction === 'right') {
        this.$refs.actionsActiveSlider.prev()
      }
    }
  },
  async mounted() {
    if(this.$store.getters.locations.length === 0)
      await this.$store.dispatch('getLocations')

    if(Object.keys(this.$store.getters.headSlider).length === 0 || Object.keys(this.$store.getters.aboutBlocks).length === 0 || Object.keys(this.$store.getters.partnersHome).length === 0)
      await this.$store.dispatch('getHome')
  }
};
</script>