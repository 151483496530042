import apiServicesCategoryController from "../sdk/classes/controllers/apiServicesCategoryController";
import ServiceController from "../sdk/classes/controllers/apiServiceController";
import TeamMemberController from "../sdk/classes/controllers/apiTeamMemberController";

export default {
    state: {
        servicesCategories: [],
        services: [],
        workers: []
    },
    getters: {
        servicesCategories(state) {
            return state.servicesCategories
        },
        services(state) {
            return state.services
        },
        workers(state) {
            return state.workers
        },
        categoryByUrl(state) {
            return url => {
                if(state.servicesCategories !== null)
                    return state.servicesCategories.find(category => category.url === url)
            }
        }
    },
    mutations: {
        loadCategories(state, payload) {
            payload.forEach(function (item) {
                state.servicesCategories.push(item)
            })
        },
        loadServices(state, payload) {
            state.services = []
            payload.forEach(function (item) {
                state.services.push(item)
            })
        },
        loadWorkers(state, payload) {
            state.workers = []
            payload.forEach(function (item) {
                state.workers.push(item)
            })
        },
    },
    actions: {
        async getCategories ({commit}) {
            try{
                let categories = await (new apiServicesCategoryController).getAll();
                commit('loadCategories', categories)
            } catch (e) {
                console.log(e)
            }
        },
        async getServices ({commit}) {
            try{
                let services = await (new ServiceController).getAll();
                commit('loadServices', services)
            } catch (e) {
                console.log(e)
            }
        },
        async getWorkers ({commit}) {
            try{
                let workers =await (new TeamMemberController).getAll(null, 1);
                commit('loadWorkers', workers)
            } catch (e) {
                console.log(e)
            }
        },
    }
}