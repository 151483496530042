<template lang="pug">
  v-app
    v-navigation-drawer(app, temporary, v-model="drawer").mobile-menu
      .wrap
        router-link(to="/" tag="a" class="logo")
          img(src="./img/logo.png")
        nav
          ul
            li(v-for="link in links", :key="link.title")
              template(v-if="!link.hasOwnProperty('category')")
                router-link(:to="link.url") {{ link.title }}
              template(v-else)
                template(v-for="subLink in servicesCategories")
                  router-link(:to="`/service/${subLink.url}`") {{ subLink.name }}
        //router-link(to="/thanks" tag="a" class="subscribe text-center btn") приєднатися
        //a(href="https://forms.gle/eo5DN1RrqNX5eemX6").subscribe.text-center.btn приєднатися
        button.subscribe.text-center.btn(@click="registration = true") приєднатися
        ul.social(v-if="Object.keys(contacts).length > 0")
          li(v-for="social in contacts.socials")
            a(:href="social.link")
              i.fab.fa-facebook-f(v-if="social.name === 'Facebook'")
              i.fab.fa-instagram(v-if="social.name === 'Instagram' || social.name === 'LinkedIn'")
              i.fab.fa-viber(v-if="social.name === 'Viber'")
              i.fab.fa-telegram-plane(v-if="social.name === 'Telegram'")

    v-app-bar(app height="auto" shrink-on-scroll :class="headerClass")
      .container
        .row.align-items-center
          .col-lg-2.col-md-4.col-4
            v-toolbar-title
              router-link(to="/" tag="a" class="logo")
                img(src="./img/logo.png")
          .col-6.d-lg-block.d-none
            nav
              ul
                li(v-for="link in links", :key="link.title")
                  template(v-if="!link.hasOwnProperty('category')")
                    router-link(:to="link.url") {{ link.title }}
                  template(v-else)
                    v-speed-dial(v-model='fab' open-on-hover transition="slide-y-transition"  direction="bottom")
                      template(v-slot:activator='')
                        | {{ link.title }}
                      template(v-for="subLink in servicesCategories")
                        router-link(:to="`/service/${subLink.url}`") {{ subLink.name }}

          .col-2.d-lg-block.d-none
            //router-link(to="/thanks" tag="a" class="subscribe text-center btn") приєднатися
            //a(href="https://forms.gle/eo5DN1RrqNX5eemX6").subscribe.text-center.btn приєднатися
            button.subscribe.text-center.btn(@click="registration = true") приєднатися
          .col-2.d-lg-block.d-none
            ul.social(v-if="Object.keys(contacts).length > 0")
              li(v-for="social in contacts.socials")
                a(:href="social.link" v-if="social.name === 'Facebook' || social.name === 'Instagram' || social.name === 'LinkedIn'")
                  i.fab.fa-facebook-f(v-if="social.name === 'Facebook'")
                  i.fab.fa-instagram(v-if="social.name === 'Instagram' || social.name === 'LinkedIn'")
          .col-md-8.col-8.d-lg-none.d-block
            button.btn(@click="drawer = !drawer").open-menu
              i.fas.fa-bars

    v-content
      router-view
    v-dialog(v-model='registration' max-width='991')
      v-card
        //.modal-title Заповніть заявку на вступ
        v-card-title.justify-center Заповніть заявку на вступ
        v-card-text
          v-form(v-model="valid" lazy-validation)
            .container
              .row
                .col-md-4.col-12
                  v-text-field(v-model="registrationForm.firstname" label="Ім'я" :rules="[v=>!!v||`Поле обов'язково до заповнення`, v=>/^[\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F]+$/.test(v)||'Тільки букви']" )
                .col-md-4.col-12
                  v-text-field(v-model="registrationForm.lastname" label="Прізвище" :rules="[v=>!!v||`Поле обов'язково до заповнення`, v=>/^[\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F]+$/.test(v)||'Тільки букви']" )
                .col-md-4.col-12
                  v-text-field(v-model="registrationForm.surname" label="По батькові" :rules="[v=>!!v||`Поле обов'язково до заповнення`, v=>/^[\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F]+$/.test(v)||'Тільки букви']" )
                .col-12
                  v-text-field(v-model="registrationForm.phone" label="Телефон" :rules="[v=>!!v||`Поле обов'язково до заповнення`, v=>/^[0-9]+$/.test(v)||'Тільки цифри']" )
                .col-12
                  pre {{ registrationForm.birthday }}
                  v-menu(v-model='datePicker' :close-on-content-click='false' transition='scale-transition' offset-y='' max-width='290px' min-width='auto')
                    template(v-slot:activator='{ on, attrs }')
                      v-text-field(v-model='computedBirthday' label="Дата народження" prepend-icon='mdi-calendar' v-bind='attrs' v-on='on' :rules="[v=>!!v||`Поле обов'язково до заповнення`]" readonly)
                    v-date-picker(v-model='birthday' @input='datePicker = false' locale="uk")
                .col-12
                  .row.align-items-center
                    .col-md-1
                      p.margin-0 УБД
                    .col-md-7
                      .row
                        .col-sm-4
                          //:rules="[v=>/^[\u0400-\u052F\u2DE0-\u2DFF\uA640-\uA69F]+$/.test(v)||'Тільки букви']"
                          v-text-field(v-model="registrationForm.ubds" :disabled="forgotUBD" label="Серія")
                        .col-sm-8
                          v-text-field(v-model="registrationForm.ubdn" label="Номер" :rules="[v=>!!v||`Поле обов'язково до заповнення`, v=>/^[0-9]+$/.test(v)||'Тільки цифри']" v-if="!forgotUBD")
                          v-text-field(v-model="registrationForm.ubdn" disabled label="Номер" v-else)
                    .col-md-4
                      v-checkbox(v-model="forgotUBD" label="Не пам'ятаю")
                .col-12
                  .row.align-items-center
                    .col-sm-6
                      v-select(v-model="registrationForm.city" :items="cities" label="Виберіть місто" item-text="label" item-value="value" :rules="[v => !!v || `Поле обов'язково до заповнення`]")
                    .col-sm-6
                      v-select(v-model="registrationForm.cat" :items="categories" label="Оберіть категорію" item-text="label" item-value="value" :rules="[v => !!v || `Поле обов'язково до заповнення`]")
                .col-12.d-flex.justify-center
                  v-btn(class="ma-2" color="success" @click="sendRegistration" :loading="registrationSending" :disabled="!valid") Надіслати

    footer
      .container
        template(v-if="contacts.email.length > 0")
          .row.justify-center
            .col-md-2
              ul.links
                li(v-for="link in footerLinks", :key="link.title")
                  router-link(:to="link.url") {{ link.title }}
            .col-md-4
              ul.links
                li(v-for="link in servicesCategories", :key="link.title")
                  router-link(:to="`/service/${link.url}`") {{ link.name }}
            .col-md-4
              .contacts-list
                .contacts-list-item
                  img(src="./img/footer-location.png")
                  .contacts-list-item-text
                    p(v-for="address in contacts.address") {{ address }}
                .contacts-list-item
                  img(src="./img/footer-phone.png")
                  .contacts-list-item-text
                    a(:href="`tel:${phone}`" v-for="phone in contacts.phones") {{ phone }}
                .contacts-list-item
                  img(src="./img/footer-time.png")
                  .contacts-list-item-text
                    p {{ contacts.time }}
                .contacts-list-item
                  img(src="./img/footer-mail.png")
                  .contacts-list-item-text
                    a(:href="`mailto:${contacts.email}`") {{ contacts.email }}
              .social
                a(:href="social.link" v-for="social in contacts.socials")
                  i.fab.fa-facebook-f(v-if="social.name === 'Facebook'")
                  i.fab.fa-instagram(v-if="social.name === 'Instagram'")
                  i.fab.fa-viber(v-if="social.name === 'Viber'")
                  i.fab.fa-telegram-plane(v-if="social.name === 'Telegram'")
        template(v-else)
          .row.justify-center
            .col-md-12.col-lg-6.text-center
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>
import ApiRequest from './sdk/classes/components/apiRequest'

export default {
  data() {
    return {
      drawer: false,
      fab: false,
      valid: false,
      datePicker: false,
      forgotUBD: false,
      registration: false,
      registrationSending: false,
      birthday: '',
      cities: [
        'Миколаїв'
      ],
      categories: [
        'Учасник АТО / ООС',
        'В ПО',
        'Чинний військовослужбовець',
        'Член сім\'ї учасника АТО / Член сім\'ї загиблого',
        'Особистість з інвалідністю в слідстві війни',
        'УБС інтернаціонал',
        'Ветеран національної поліції',
      ],
      registrationForm: {
        firstname: '',
        lastname: '',
        surname: '',
        birthday: '',
        phone: '',
        ubds: '',
        ubdn: '',
        city: '',
        cat: ''
      },
      footerLinks: [
        {
          title: 'Про нас',
          url: '/about'
        },
        {
          title: 'Події',
          url: '/actions'
        },
        {
          title: 'Новини',
          url: '/news'
        },
        {
          title: 'Локації',
          url: '/locations'
        },
        {
          title: 'Контакти',
          url: '/contacts'
        },
      ],
      footerLinksServices: [
        {
          title: 'Соціальна допомога',
          url: '/service/1',
        },
        {
          title: 'Психологічна допомога',
          url: '/service/2',
        },
        {
          title: 'Профорієнтація',
          url: '/service/3',
        },
        {
          title: 'Юридичне консультування',
          url: '/service/4',
        },
      ],
      links: [
        {
          title: 'Про нас',
          url: '/about'
        },
        {
          title: 'Послуги',
          url: '',
          category: true
        },
        {
          title: 'Події',
          url: '/actions'
        },
        {
          title: 'Новини',
          url: '/news'
        },
        {
          title: 'Локації',
          url: '/locations'
        },
        /*{
            title: 'Галерея',
            url: '/gallery'
        },*/
        {
          title: 'Контакти',
          url: '/contacts'
        },
      ],
      //contacts: {}
    }
  },
  methods: {
    async sendRegistration() {
      this.registrationSending = true
      let request = new ApiRequest()
      this.registrationForm.birthday = this.computedBirthday
      await request.request('/registration/new-user', this.registrationForm, 'post')
      this.registrationSending = false
      this.registration = false
      this.registrationSending = false
      this.registrationForm = {
        firstname: '',
        lastname: '',
        surname: '',
        birthdate: '',
        phone: '',
        ubds: '',
        ubdn: '',
        city: '',
        cat: ''
      }
      this.$router.push({ name: 'ThanksPage' })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}.${month}.${day}`
    },
  },
  computed: {
    computedBirthday() {
      return this.formatDate(this.birthday)
    },
    headerClass() {
      if (this.$route.name === 'Home' || this.$route.name === 'Actions')
        return 'home'
      return 'dark'
    },
    contacts() {
      return this.$store.getters.contactsOptions
    },
    servicesCategories() {
      return this.$store.getters.servicesCategories
    },
  },
  async mounted() {
    await this.$store.dispatch('getContacts')
    if (this.$store.getters.servicesCategories.length === 0)
      await this.$store.dispatch('getCategories')
  }
};
</script>

<style lang="sass">
@import "src/sass/main"
</style>
