import ApiController from './base/apiController'
import PvServicesCategoryModel from "../models/servicesCategoryModel";

export default class PvApiServicesCategoryController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'services-groups';
        this._modelClass = PvServicesCategoryModel;
    }
}