import ApiController from './base/apiController'
import NewsModel from "../models/newsModel";

export default class PvApiNewsController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'news';
        this._modelClass = NewsModel
    }
}