//import store from "../../../../store";

export default class PvApiAuthenticator {
    constructor() {
        PvApiAuthenticator.initAuthData();
        PvApiAuthenticator.initBearer();
    }

    static initBearer() {
        if (PvApiAuthenticator._bearer === undefined || PvApiAuthenticator._bearer === null) {
            if (localStorage.getItem('Bearer'))
                PvApiAuthenticator._bearer = localStorage.getItem('Bearer');
            else
                PvApiAuthenticator._bearer = null;
        }
    }

    static initAuthData() {
        if (PvApiAuthenticator._authData === undefined)
            PvApiAuthenticator._authData = null;
    }

    static isAuthenticated() {
        PvApiAuthenticator.initBearer();
        return PvApiAuthenticator._bearer !== null
    }

    static set bearer(bearer) {
        if (bearer === null) {
            localStorage.removeItem('Bearer');
            PvApiAuthenticator._bearer = null;
        } else {
            PvApiAuthenticator._bearer = bearer.replace('Bearer ', '');
            localStorage.setItem('Bearer', PvApiAuthenticator._bearer);
        }
    }

    static setAuthData(login, password) {
        PvApiAuthenticator.bearer = null;
        PvApiAuthenticator._authData = btoa(encodeURIComponent(login + ': ' + password).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    }

    static hasAuthData() {
        return PvApiAuthenticator._authData !== null;
    }

    authenticate() {
        if (PvApiAuthenticator.isAuthenticated()) {
            this._request.addHeader('Authorization', 'Bearer ' + PvApiAuthenticator._bearer);
            return true;
        } else if (PvApiAuthenticator.hasAuthData()) {
            this._request.addHeader('Authorization', 'Basic ' + PvApiAuthenticator._authData);
            return true;
        }
        return false;
    }

    setBearerFromResponse(response) {
        if (!PvApiAuthenticator.isAuthenticated()) {
            let header = response.getHeader('authorization');
            if (header)
                PvApiAuthenticator.bearer = header;
        }
    }
}