<template lang="pug">
  section.actions-block-wrap
    .container-fluid
      .row(v-if="actions.length > 0")
        .col-lg-4.padding-0.d-lg-block.d-none
          slick(ref="actionsActiveSlider" :options="actionsActiveSliderOptions").actions-active-slider
            .actions-slide(v-for="event in actions")
              .actions-slide-img-wrap
                img(:src="event.image").actions-slide-img
                router-link(:to="`/action/${event.url}`" tag="a" class="btn") Докладніше
                  i.fas.fa-arrow-right
              .actions-slide-description
                p.name {{ event.name }}
                .time
                  img(src="../../img/home/action-time-icon.png")
                  p {{ event.start_date }}
                .place
                  img(src="../../img/home/action-place-icon.png")
                  p {{ event.address }}
        .col-lg-1
        .col-lg-7.col-md-12.col-12.actions-slider-wrap.padding-r-0
          h2.section-head.light.light-dark Найближчі
            span  події
          slick(ref="actionsSlider" :options="actionsSliderOptions" @swipe="actionsSliderSwipe").actions-slider
            .actions-slide(v-for="event in actions")
              .actions-slide-img-wrap
                img(:src="event.image").action-img
                router-link(:to="`/action/${event.url}`" tag="a" class="btn")
                  i.fas.fa-arrow-right
              .actions-slide-description
                p.name {{ event.name }}
                .time
                  img(src="../../img/home/action-time-icon.png")
                  p {{ event.start_date }}
                .place
                  img(src="../../img/home/action-place-icon.png")
                  p {{ event.address }}
      .row.justify-center(v-else)
        .col-md-12.col-lg-6
          v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
</template>

<script>
import Slick from "vue-slick";

export default {
  name: "ResentActions",
  components: {
    Slick
  },
  data() {
    return {
      actionsSliderOptions: {
        slidesToShow: 2,
        variableWidth: true,
        arrows: false,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              initialSlide: 0,
            }
          },
          {
            breakpoint: 500,
            settings: {
              initialSlide: 0,
              variableWidth: false,
              slidesToShow: 1,
              adaptiveHeight: true,
              dots: true
            }
          }
        ]
      },
      actionsActiveSliderOptions: {
        arrows: false,
        swipe: false,
        draggable: false
      },
    }
  },
  methods: {
    actionsSliderSwipe(event, slick, direction) {
      if (direction === 'left') {
        this.$refs.actionsActiveSlider.next()
      }
      if (direction === 'right') {
        this.$refs.actionsActiveSlider.prev()
      }
    }
  },
  computed: {
    actions() {
      return this.$store.getters.actions
    }
  },
  mounted() {
    this.$store.dispatch('getActions')
  }
}
</script>

<style lang="sass">
section.actions-block-wrap
  background-color: #B9C7A1
  background-image: url('../../img/home/actions-waves-bg.png')
  background-repeat: no-repeat
  background-position: right center
  background-size: contain
  padding: 0

  .section-head
    margin-bottom: 68px
    @media (max-width: 1199px)
      margin-bottom: 30px
    @media (max-width: 500px)
      margin-bottom: 20px

  .actions-slider
    .slick-dots
      padding-top: 20px
      padding-left: 0 !important

      li
        button
          background-color: color(bg)

        &.slick-active
          button
            background-color: #fff

  .actions
    &-slider-wrap
      padding-top: 152px
      padding-bottom: 128px
      @media (max-width: 1199px)
        padding-top: 30px
        padding-bottom: 30px
      @media (max-width: 1200px)
        padding-right: 15px !important

      img
        width: 100%
        height: 100%
        object-fit: cover

    &-active-slider
      height: 100%

      .slick-list,
      .slick-track,
      .slick-slide > div
        height: 100%

      .actions
        &-slide
          max-width: unset
          padding-right: 0
          display: flex !important
          flex-direction: column
          height: 100%
          @media (max-width: 500px)
            padding-right: 0
            max-width: unset

          &-img
            &-wrap
              height: 582px

              .btn
                height: unset
                width: auto

                i
                  margin-left: 10px

          &-description
            height: 100%
            padding-top: 56px
            padding-bottom: 25px
            //height: 312px
            display: flex
            flex-direction: column
            justify-content: center

            .name
              font-size: 36px
              line-height: 42px
              margin-bottom: 28px

            .time,
            .place
              font-size: 20px
              line-height: 23px

              &:not(:last-child)
                margin-bottom: 17px

    &-slide
      padding-right: 48px
      max-width: 448px
      @media (max-width: 500px)
        padding-right: 0
        max-width: unset

      &-img
        width: 100%
        height: 100%
        object-fit: cover

        &-wrap
          height: 245px
          position: relative

          .btn
            position: absolute
            top: 100%
            right: 32px
            width: 48px
            height: 48px
            display: flex
            align-items: center
            justify-content: center
            transform: translateY(-50%)

      &-description
        background-color: #ffffff
        padding: 16px 32px 18px

        .name
          font-size: 26px
          line-height: 30px
          margin-bottom: 10px

        .time,
        .place
          display: flex
          align-items: center

          &:not(:last-child)
            margin-bottom: 10px

          p
            color: color(sub-text)
            font-size: 14px
            margin-bottom: 0

          img
            width: 20px
            height: 20px
            object-fit: contain
            margin-right: 10px
</style>