<template lang="pug">
    .not-found
        section.not-found-wrap
            .container
                .row
                    .col-12
                        .section-head.text-center 404
                        .section-sub-head.text-center Запитувана вами сторінка - не знайдена
                .row.justify-center
                    .col-md-10.col-lg-8
                        .row
                            .col-md-6
                                p.head Можете перейти на ці:
                                ul.menu
                                    li(v-for="link in links", :key="link.title")
                                        router-link(:to="link.url") {{ link.title }}
                            .col-md-6
                                p.head Можливі причини:
                                ul.reasons
                                    li ви ввели неправильну адресу
                                    li сторінка, яку ві шукаєте - видалена
                                    li у вас немає достатнього доступу для перегляду цієї сторінки
</template>

<script>
    export default {
        name: "notFound",
        data () {
            return {
                links: [
                    {
                        title: 'Головна',
                        url: '/'
                    },
                    {
                        title: 'Про нас',
                        url: '/about'
                    },
                    /*{
                        title: 'Послуги',
                        url: '/services'
                    },*/
                    {
                        title: 'Події',
                        url: '/actions'
                    },
                    /*{
                        title: 'Галерея',
                        url: '/gallery'
                    },*/
                    {
                        title: 'Новини',
                        url: '/news'
                    },
                    {
                        title: 'Контакти',
                        url: '/contacts'
                    },
                ]
            }
        },
    }
</script>

<style >

</style>