export default class PvApiValidator {

    static validateInteger(value, min, max, nullable = true) {
        if (min === undefined || min === null) {
            min = -2147483648;
        }
        if (max === undefined || max === null) {
            max = -2147483647;
        }

        if (!nullable && value === null)
            return 'Значение не может быть пустым';
        else if (!Number.isInteger(parseInt(value)) && !nullable)
            return 'Значение должно быть числом';
        else if (value < min)
            return 'Значение должно быть больше чем ' + min;
        else if (value > max)
            return 'Значение должно быть меньше чем ' + max;

        return true;
    }

    static validateString(value, max, nullable) {
        if (max === undefined || max === null) {
            max = 4294967295;
        }

        if (!nullable && value === null)
            return 'Значение не может быть пустым';
        else if (typeof value !== "string" && !(value instanceof String))
            return 'Значение должно быть строкой';
        else if (value.length > max)
            return 'Строка должна быть короче ' + max + ' символов';

        return true;
    }

    static validateIn(value, range, nullable) {
        if (!nullable && value === null)
            return 'Значение не может быть пустым';
        else if (range.indexOf(value) === -1 && !nullable)
            return 'Необходимо выбрать одно из предложенных значений';

        return true;
    }

}