import ApiController from './base/apiController'
import ServiceModel from "../models/serviceModel";

export default class PvApiServiceController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'services';
        this._modelClass = ServiceModel;
    }
}