<template lang="pug">
  .actions
    section.head(:style="{backgroundImage: `url(${head.image})`}" v-if="Object.keys(head).length > 0")
      section.breadcrumbs
        .container-custom
          .row
            .col-md-12
              ul.breadcrumbs
                li
                  router-link(to="/" tag="a") Головна
                li Події
      .container-fluid.d-lg-block.d-none
        .row
          .col-lg-7
          .col-lg-5.padding-r-0
            .has-idea.absolute
              .has-idea-head Маєте свою  iдею подiї?
              .has-idea-text Перейдіть за посиланням та заповніть форму. Ми готові допомогати в реалізації всіх розумних ініціатив.
              button.btn подати пропозицію
      .container-custom
        .row
          template(v-if="Object.keys(head).length > 0")
            .col-md-12.col-lg-6
              h2.section-head
                template(v-for="(word, index) in head.name.split(' ')")
                  template(v-if="index + 1 !== head.name.split(' ').length")  {{ word }}
                  template(v-else)
                    span  {{ word }}
              p.head-text(v-html="head.description")
          template(v-else)
            .col-md-12.col-lg-6
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
          .col-md-12.d-lg-none
            .has-idea
              .has-idea-head Маєте свою  iдею подiї?
              .has-idea-text Перейдіть за посиланням та заповніть форму. Ми готові допомогати в реалізації всіх розумних ініціатив.
              button.btn подати пропозицію
    section.actions
      .container-custom
        .row.d-none
          .col-12
            .filter-wrap
              p.filter-hint Дивитися події:
              .filter-items
                button(v-for="filter in filters" :class="{ active: activeFilter === filter }" @click="activeFilter = filter").filter-item.d-sm-inline-block.d-none {{ filter }}
                v-select(:items="filters" label="Дивитися події" solo color="#90D21F" v-model="activeFilter" hide-details item-color="#90D21F" full-width).d-flex.d-sm-none
        .row(v-if="actions")
          .col-12
            pre(style="display: none") {{ actions }}
          template(v-if="actions.length > 0")
            .col-lg-4.col-md-6.col-sm-6.col-12(v-for="event in actions")
              .action
                .action-img-wrap
                  img(:src="event.image").action-img
                  router-link(:to="`/action/${event.url}`" tag="a" class="btn") докладніше
                    i.fas.fa-arrow-right
                .action-description
                  p.name {{ event.name }}
                  .time
                    img(src="../../img/home/action-time-icon.png")
                    p {{ event.start_date }}
                  .place
                    img(src="../../img/home/action-place-icon.png")
                    p {{ event.address }}
        template(v-else)
          .row.justify-center
            .col-md-12.col-lg-6
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
        .row.d-none
          .col-12
            button.btn.block-center дивитися більше
</template>

<script>
import PageModel from "../../sdk/classes/models/pageModel";
import EventController from "../../sdk/classes/controllers/apiEventController";

export default {
  name: "Actions",
  data() {
    return {
      activeFilter: 'всі',
      filters: [
        'всі',
        'найближчі',
        'минулі',
      ]
    }
  },
  computed: {
    head() {
      return this.$store.getters.actionsPage
    },
    actions() {
      return this.$store.getters.actions
    },
  },
  mounted() {
    this.$store.dispatch('getActionsPage')
    this.$store.dispatch('getActions')
  }
}
</script>

<style>

</style>