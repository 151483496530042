<template lang="pug">
  .news-list-page
    section.head.padding-0
      section.breadcrumbs
        .container-custom
          .row
            .col-md-12
              ul.breadcrumbs
                li
                  router-link(to="/" tag="a") Головна
                li  Новини
    section.news-list.before
      .container-custom
        .row.justify-center
          .col-12
            h2.section-head.section-head-dark.main.text-center Новини
          .col-lg-4.col-md-6.col-12
            news-subscribe
          template(v-for="(item, index) in newsServer" v-if="newsServer")
            .col-md-6.col-12.news(:class="[index === 0 ? 'col-lg-8' : 'col-lg-4']" v-if="index < 7")
              template(v-if="!item.youtube")
                router-link(tag="div" :to="`news/${item.url}`").news-wrap
                  .news-name {{ item.name }}
                  v-img(:src="item.image" height="100%" v-if="index === 0" max-height="688px")
                  v-img(:src="item.image" max-height="380px" v-else)
                  .news-category.d-none {{ item.category }}
                  .news-info.d-none
                    p.author {{ item.author }}
                    p.date {{ item.date }}
              template(v-else)
                .play(v-if="!item.play")
                  i.fas.fa-play-circle(@click="item.play = true")
                .news-name(v-if="!item.play") {{ item.name }}
                v-img(:src="`https://img.youtube.com/vi/${item.youtube}/maxresdefault.jpg`" height="100%" v-if="!item.play")
                iframe(:src="`https://www.youtube.com/embed/${item.youtube}?&autoplay=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="item.play")
                //.news-category {{ item.category }}
          template(v-else)
            .col-md-6.col-12
              v-progress-circular(:size="70" :width="7" color="#7ad000" indeterminate)
    resent-actions
    section.news-list.after
      .container-custom
        .row.justify-center.d-none.d-lg-flex.margin-b-20
          .col-12
            .grid-wrap
              div(v-for="(item, index) in newsServer" :class="`news-grid-${index-6}`" v-if="index > 6 && index < 16 && newsServer")
                .news
                  template(v-if="!item.youtube")
                    router-link(tag="div" :to="`news/${item.url}`").news-wrap
                      .news-name {{ item.name }}
                      v-img(:src="item.image" height="100%" v-if="index === 7" max-height="708px")
                      v-img(:src="item.image" height="100%" v-else max-height="400px")
                      .news-category.d-none {{ item.category }}
                      .news-info.d-none
                        p.author {{ item.author }}
                        p.date {{ item.date }}
                  template(v-else)
                    .play(v-if="!item.play")
                      i.fas.fa-play-circle(@click="item.play = true")
                    .news-name(v-if="!item.play") {{ item.name }}
                    v-img(:src="`https://img.youtube.com/vi/${item.youtube}/maxresdefault.jpg`" height="100%" v-if="!item.play")
                    iframe(:src="`https://www.youtube.com/embed/${item.youtube}?&autoplay=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="item.play")
                    .news-category.d-none {{ item.category }}
        .row.justify-center
          .col-lg-4.col-md-6.col-12.d-lg-none.news(v-for="(item, index) in newsServer" v-if="index > 6 && index < 16 && newsServer")
            template(v-if="!item.youtube")
              router-link(tag="div" :to="`news/${item.url}`").news-wrap
                .news-name {{ item.name }}
                v-img(:src="item.image" height="100%")
                .news-category.d-none {{ item.category }}
                .news-info.d-none
                  p.author {{ item.author }}
                  p.date {{ item.date }}
            template(v-else)
              .play(v-if="!item.play")
                i.fas.fa-play-circle(@click="item.play = true")
              .news-name(v-if="!item.play") {{ item.name }}
              v-img(:src="`https://img.youtube.com/vi/${item.youtube}/maxresdefault.jpg`" height="100%" v-if="!item.play")
              iframe(:src="`https://www.youtube.com/embed/${item.youtube}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="item.play")
              .news-category.d-none {{ item.category }}
          //template(v-for="item in newsLoaded")
          template(v-for="(item, index) in newsServer" v-if="index > 15 && newsServer && !canLoad")
            .col-lg-4.col-md-6.col-12.news
              template(v-if="!item.youtube")
                router-link(tag="div" :to="`news/${item.url}`").news-wrap
                  .news-name {{ item.name }}
                  v-img(:src="item.image" height="100%" max-height="334px")
                  .news-category.d-none {{ item.category }}
                  .news-info.d-none
                    p.author {{ item.author }}
                    p.date {{ item.date }}
              template(v-else)
                .play(v-if="!item.play")
                  i.fas.fa-play-circle(@click="item.play = true")
                .news-name(v-if="!item.play") {{ item.name }}
                v-img(:src="`https://img.youtube.com/vi/${item.youtube}/maxresdefault.jpg`" height="100%" max-height="334px" v-if="!item.play")
                iframe(:src="`https://www.youtube.com/embed/${item.youtube}?&autoplay=1`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="item.play")
                .news-category.d-none {{ item.category }}
        .row.justify-center(v-if="newsServer && canLoad && newsServer.length > 16")
          .col-12
            button.load-more(@click="loadMoreNews")
              i.fas.fa-sync-alt
              | дивитися більше
</template>

<script>
import ResentActions from "../Shared/ResentActions";
import NewsSubscribe from "../Shared/NewsSubscribe";
import NewsController from "../../sdk/classes/controllers/apiNewsController";

export default {
  name: "NewsList",
  components: {
    ResentActions,
    NewsSubscribe
  },
  data() {
    return {
      news: [
        {
          id: 1,
          video: true,
          play: false,
          videoId: '4QrvYO_dByc',
          name: 'Відповіді на всі важливі запитання про літній відпочинок',
          category: 'Рубрика',
        },
        {
          id: 2,
          name: 'Довгоочікуване закінчення ремонту',
          author: 'Имя Автора',
          category: 'Рубрика',
          date: '10.11.20'
        },
        {
          id: 3,
          name: 'Угода про співпрацю з заводом виробника вікон і дверей «Триботехніка»',
          author: 'Имя Автора',
          category: 'Рубрика',
          date: '10.11.20'
        },
        /*{
            id: 4,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 5,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 6,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 7,
            video: true,
            videoLink: 'video link',
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            category: 'Рубрика',
        },*/
      ],
      newsAfter: [
        /*{
            id: 1,
            video: true,
            videoLink: 'video link',
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            category: 'Рубрика',
        },
        {
            id: 2,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 3,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 4,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 5,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 6,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 7,
            video: true,
            videoLink: 'video link',
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            category: 'Рубрика',
        },
        {
            id: 8,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },
        {
            id: 9,
            name: 'Важливе свято для нашої країни - День Соборностi України!',
            author: 'Имя Автора',
            category: 'Рубрика',
            date: '10.11.20'
        },*/
      ],
      newsServer: null,
      newsLoaded: [],
      canLoad: true
    }
  },
  methods: {
    loadMoreNews() {
      //this.newsLoaded = this.newsLoaded.concat(this.news)
      this.canLoad = false
    }
  },
  async mounted() {
    let getNewsList = async function () {
      try {
        let newsList = await (new NewsController).getAll();
        newsList.forEach(function (item) {
          if (item.youtube !== null)
            item['play'] = false
        })
        return newsList.reverse()
      } catch (e) {
        console.log(e)
      }
    }

    let newsList = await getNewsList()
    this.newsServer = newsList
    this.$store.commit('loadNews', newsList)
  }
}
</script>