import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

Vue.filter('uppercase', (value) => {
  return value.toUpperCase()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  async created () {

  }
}).$mount('#app');
