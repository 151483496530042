import PvModel from './base/model'
import apiPageController from "../controllers/apiPageController";

export default class PvPageModel extends PvModel {
    constructor() {
        super(new apiPageController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'name',
                max: 128,
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'url',
                max: 191,
                nullable: false
            },
            {
                validator: 'validateString',
                attribute: 'description',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'keywords',
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'title',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'h1',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'canonical',
                max: 256,
                nullable: true
            },
            {
                validator: 'validateString',
                attribute: 'robots',
                max: 64,
                nullable: true
            }
        ];
    }

    get id(){
        return super.getAttribute('id');
    }

    get name(){
        return super.getAttribute('name');
    }

    get url(){
        return super.getAttribute('url');
    }

    get title(){
        return super.getAttribute('title');
    }

    get h1(){
        return super.getAttribute('h1');
    }

    get description(){
        return super.getAttribute('description');
    }

    get keywords(){
        return super.getAttribute('keywords');
    }

    get robots(){
        return super.getAttribute('robots');
    }

    get canonical(){
        return super.getAttribute('canonical');
    }

    get created_at(){
        return super.getAttribute('created_at');
    }

    get updated_at(){
        return super.getAttribute('updated_at');
    }

    get options(){
        return super.getAttribute('options');
    }

    get links(){
        return super.getAttribute('links');
    }

    set id(value){
        super.setAttribute('id', value);
    }

    set name(value){
        super.setAttribute('name', value);
    }

    set url(value){
        super.setAttribute('url', value);
    }

    set title(value){
        super.setAttribute('title', value);
    }

    set h1(value){
        super.setAttribute('h1', value);
    }

    set description(value){
        super.setAttribute('description', value);
    }

    set keywords(value){
        super.setAttribute('keywords', value);
    }

    set robots(value){
        super.setAttribute('robots', value);
    }

    set canonical(value){
        super.setAttribute('canonical', value);
    }

    set options(value){
        super.setAttribute('options', value);
    }

}