import ApiController from './base/apiController'
import EventModel from "../models/eventModel";

export default class PvApiEventController extends ApiController {
    constructor() {
        super();

        this._baseUrl = 'events';
        this._modelClass = EventModel;
    }
}