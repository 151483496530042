import ApiValidator from "../../components/apiValidator.js";

export default class PvModel {
    constructor(controller) {
        this._attributes = {};
        this._controller = controller;
        this._errors = null;
    }

    setAttributes(attributes) {
        if (attributes === undefined)
            return false;
        this._attributes = attributes;
        return true;
    }

    setAttribute(key, value) {
        if (key === undefined || key === null || value === undefined)
            return false;
        this._attributes[key] = value;
        return true;
    }

    getAttributes() {
        return this._attributes;
    }

    getAttribute(key) {
        if (key === undefined || key === null || !this._attributes.hasOwnProperty(key))
            return null;
        return this._attributes[key];
    }

    deleteAttribute(key) {
        if (key === undefined || key === null || !this._attributes.hasOwnProperty(key))
            return false;
        delete this._attributes[key];
        return true;
    }

    validate() {
        let rules = this.rules;
        if ( rules === undefined || rules === null)
            return true;

        let res;
        let errors = {};

        rules.forEach(function (element) {
            if (element.validator === 'validateInteger') {
                res = ApiValidator.validateInteger(element.attribute, element.min, element.max, element.nullable);
            } else if (element.validator === 'validateString') {
                res = ApiValidator.validateString(element.attribute, element.max, element.nullable);
            } else if (element.validator === 'validateIn') {
                res = ApiValidator.validateIn(element.attribute, element.range, element.nullable);
            }

            if (res !== true) {
                if (errors[element.attribute]) {
                    errors[element.attribute].push(res);
                } else {
                    errors[element.attribute] = [
                        res
                    ];
                }
            }
        });

        this._errors = errors;

        if (Object.keys(this._errors).length > 0)
            return this._errors;
        return true;
    }

    static async getById(id) {
        let model = new this;
        return await model._controller.getOneModel(id);
    }

    static async getLoop(filters) {
        let model = new this;
        if (await model._controller.makeLoop(filters))
            return model._controller.loop;
        return null;
    }

    static make(modelClass, attributes) {
        let instance = new modelClass();
        instance.setAttributes(attributes);
        return instance;
    }

    async insert() {
        if (!this.validate())
            return this._errors;

        let response = await this._controller.create(this._attributes);

        if (response.httpCode === '201')
            return true;

        return response;
    }

    async update() {
        if (!this.validate())
            return this._errors;

        let response = await this._controller.update(this._attributes);

        if (response.httpCode === '200')
            return true;
        return response;
    }

    async delete() {
        if (!this.id)
            return false;

        let response = await this._controller.delete(this._attributes);

        if (response.httpCode === '204')
            return true;
        return response;
    }
}