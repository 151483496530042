<template lang="pug">
    .contacts-page
        section.breadcrumbs.padding-b-0
            .container-custom
                .row
                    .col-md-12
                        ul.breadcrumbs
                            li
                                router-link(to="/" tag="a") Головна
                            li Контакти
        contacts-block
</template>

<script>
    import ContactsBlock from "./Shared/ContactsBlock";

    export default {
        name: "Contacts",
        components: {
            ContactsBlock
        }
    }
</script>

<style scoped>

</style>