import PvModel from './base/model'
import apiServicesCategoryController from "../controllers/apiServicesCategoryController";

export default class PvServicesCategoryModel extends PvModel {
    constructor() {
        super(new apiServicesCategoryController());
    }

    get rules() {
        return [
            {
                validator: 'validateInteger',
                attribute: 'id',
                nullable: true
            },
        ];
    }

    get id(){
        return super.getAttribute('id');
    }

    get name(){
        return super.getAttribute('name');
    }

    get description(){
        return super.getAttribute('description');
    }

    get photo(){
        return super.getAttribute('photo');
    }

    get url(){
        return super.getAttribute('url');
    }

    get created_at(){
        return super.getAttribute('created_at');
    }

    get updated_at(){
        return super.getAttribute('updated_at');
    }

    set id(value){
        super.setAttribute('id', value);
    }

    set name(value){
        super.setAttribute('name', value);
    }

    set description(value){
        super.setAttribute('description', value);
    }

    set photo(value){
        super.setAttribute('photo', value);
    }

    set url(value){
        super.setAttribute('url', value);
    }
}