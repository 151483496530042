export default class PvModelList {
    constructor() {
        this._models = [];
        this._iterator = 0;
    }

    getAll() {
        return this._models;
    }

    getCount() {
        return this._models.length;
    }

    getOne(key) {
        if (!Number.isInteger(parseInt(key)))
            return null;
        return this._models[key];
    }

    isEmpty() {
        return this.getCount() === 0;
    }

    reset() {
        this._iterator = null;
    }

    hasNext() {
        return this._iterator < this.getCount()
    }

    getNext() {
        if (this.isEmpty())
            return null;

        return this._models[this._iterator++];
    }

    get() {
        if (this.isEmpty())
            return null;

        return this._models[this._iterator];
    }

    insertOne(model) {
        if (model !== null)
            this._models.push(model);
    }

    insertMany(models) {
        if (models !== null){
            models.forEach((item) => {
                this._models.push(item)
            })
        }
    }

    static make(modelClass, attributesArray) {
        let instance = new this();
        attributesArray.forEach(function (element) {
            instance.insertOne(modelClass.make(modelClass, element));
        });
        return instance;
    }
}